import React, { useState, useEffect } from "react";
import { Layout, notification } from "antd";
import MenuTop from "../../../components/Admin/MenuTop";
import MenuAdmin from "../../../components/Admin/Menu/Menu";
import Modal from "../../../components/General/Modal";
import GroupForm from "../../../components/Admin/GroupForm";
import FileProducts from "../../../components/Admin/FileProducts";
import ImportMenu from "../../../components/Admin/ImportMenu";
import { getAccessTokenApi, logout } from "../../../api/auth";
import { getCompanyIdApi, getCompanyApi } from "../../../api/company";
import { getCompleteMenu } from "../../../api/menu";
import { userLogOut } from "../../../utils/general";

import "./Menu.scss";

export default function Menu() {
  const [menu, setMenu] = useState();

  const [reloadMenu, setReloadMenu] = useState(false);
  const [infoCompany, setInfoCompany] = useState();

  let [loading, setloading] = useState(true);

  const [isVisible, setIsVisible] = useState(false);
  const [contextModal, setContextModal] = useState(false);

  const [showPickUpMenu, setShowPickUpMenu] = useState(false);
  const [title, setTitle] = useState(false);

  const token = getAccessTokenApi();
  const companyId = getCompanyIdApi();

  useEffect(() => {
    if (token == null) {
      userLogOut();
    }
  }, [token]);

  useEffect(() => {
    getCompleteMenu(token, companyId).then((response) => {
      if (response?.statusCode === 401) {
        notification["error"]({
          message: "Usuario no autorizado.",
        });

        setTimeout(() => {
          logout();
          window.location.reload();
        }, 1500);
        return;
      } else {
        setMenu(response);
        setloading(false);
      }
    });
    return () => {
      setMenu([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, reloadMenu]);

  useEffect(() => {
    getCompanyApi(token, companyId).then((response) => {
      if (response !== undefined) {
        if (response.statusCode !== 401) {
          setShowPickUpMenu(
            response.delivery === false && response.pickUp === false
              ? false
              : true
          );
          setInfoCompany(response);
        }
      }
    });
  }, [token, companyId]);

  const openModal = () => {
    setIsVisible(true);
    setContextModal(
      <GroupForm
        setIsVisible={setIsVisible}
        setTitle={setTitle}
        // setReloadMenu={setReloadMenu}
        // setloading={setloading}
        menu={menu}
        setMenu={setMenu}
      />
    );
  };
  const openModalFile = () => {
    setIsVisible(true);
    setContextModal(
      <FileProducts
        setIsVisible={setIsVisible}
        setTitle={setTitle}
        // setReloadMenu={setReloadMenu}
        // setloading={setloading}
      />
    );
  };
  const openModalMenu = () => {
    setIsVisible(true);
    setContextModal(
      <ImportMenu setIsVisible={setIsVisible} setTitle={setTitle} />
    );
  };

  return (
    <Layout className="menu-admin">
      {infoCompany && menu && (
        <div>
          {infoCompany.fatherCompanyId === null ? (
            <MenuTop
              title={"Menú"}
              icon="add"
              action
              onAction={() => openModal()}
              fileAction={() => openModalFile()}
            />
          ) : infoCompany.fatherCompanyId !== null && menu.length === 0 ? (
            <MenuTop
              title={"Menú"}
              icon="add"
              action
              onAction={() => openModal()}
              fileAction={() => openModalFile()}
              menuAction={() => openModalMenu()}
            />
          ) : (
            <MenuTop
              title={"Menú"}
              icon="add"
              action
              onAction={() => openModal()}
              fileAction={() => openModalFile()}
            />
          )}
        </div>
      )}

      <MenuAdmin
        menu={menu}
        loading={loading}
        setReloadMenu={setReloadMenu}
        showPickUpMenu={showPickUpMenu}
      />
      <Modal
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        title={title}
        footer={false}
      >
        {contextModal}
      </Modal>
    </Layout>
  );
}
