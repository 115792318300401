import React, { useState } from 'react';
import { Col, Row, Table, Tag, Typography, Input, Space, Button } from 'antd';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { formatPhoneNumber } from '../../../utils/general';

export default function ListUsers(props) {
    const { data, loading } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    // const [sortedInfo, setSortedInfo] = useState({});
    const { Title } = Typography;

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <h3>{text}</h3>,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Apellido',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Compañia',
            dataIndex: 'companyId',
            key: 'companyId',
            render: (text) => <h3>{text}</h3>,
            ...getColumnSearchProps('companyId'),
        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Teléfono',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render: (phoneNumber) => <p>{formatPhoneNumber(phoneNumber)}</p>,
        },
        {
            title: 'Tipo',
            key: 'userType',
            dataIndex: 'userType',
            filters: [
                { text: 'Administrador', value: 1 },
                { text: 'Manager Suc', value: 7 },
            ],
            filteredValue: filteredInfo.userType || null,
            onFilter: (value, record) => record.userType === value,
            render: (active) => (
                <Tag color={active === 1 ? 'blue' : 'volcano'}>
                    {active === 1  ? 'Administrador'.toUpperCase() : 'Manager Suc.'.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Estatus',
            key: 'active',
            dataIndex: 'active',
            filters: [
                { text: 'Activo', value: true },
                { text: 'Inactivo', value: false },
            ],
            filteredValue: filteredInfo.active || null,
            onFilter: (value, record) => record.active === value,
            render: (active) => (
                <Tag color={active ? 'green' : 'red'}>
                    {active ? 'Activo'.toUpperCase() : 'Incativo'.toUpperCase()}
                </Tag>
            ),
        },
        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <Space size='middle'>
        //             <a>Invite {record.name}</a>
        //             <a>Delete</a>
        //         </Space>
        //     ),
        // },
    ];
    return (
        <div>
            <Row gutter={[10, 20]}>
                <Col xs={24} md={16}>
                    <Title level={2}>Lista de Usuarios Administradores</Title>
                </Col>
                <Col xs={24} md={8}>
                    <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button>
                    {/* <Button type='primary'>Crear un Usuario</Button> */}
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                onChange={handleChange}
                scroll={{ x: 950 }}
            />
        </div>
    );
}
