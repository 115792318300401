import React, { useState, useEffect } from "react";

import {
  Form,
  Button,
  Spin,
  notification,
  Row,
  Col,
  Divider,
  Select,
} from "antd";
import { VerticalAlignBottomOutlined } from "@ant-design/icons";
import {
  getCompaniesApi,
  getCompanyApi,
  getCompanyIdApi,
} from "../../../api/company";
import { getAccessTokenApi, logout } from "../../../api/auth";
import { userLogOut, messageError } from "../../../utils/general";
import { importMenuApi } from "../../../api/menu";
import { IS_TEST_COMPANY } from "../../../utils/constants";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const ImportMenu = (props) => {
  const { setIsVisible, setTitle } = props;
  const token = getAccessTokenApi();
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState(false);
  const [sucursalSelect, setSucursalSelect] = useState("");
  const { Option } = Select;
  const [senderId, setSenderId] = useState();
  const [infoCompany, setInfoCompany] = useState();
  const destinationId = getCompanyIdApi();

  useEffect(() => {
    if (token == null) {
      userLogOut();
    }
    setTitle("Importar menú");
    getSucursales();
  }, [token]);

  function Cancel() {
    form.resetFields();
    setIsVisible(false);
  }

  useEffect(() => {
    getCompanyApi(token, destinationId).then((response) => {
      if (response !== undefined) {
        if (response.statusCode !== 401) {
          setInfoCompany(response);
        }
      }
    });
  }, [token, destinationId]);

  const getSucursales = async () => {
    setLoadingForm(true);
    getCompanyApi(token, destinationId).then((result) => {
      if (result !== undefined) {
        if (result.statusCode !== 401) {
          let idFather = result.fatherCompanyId;
          //poner false al subir a produccion
          const filters = `isTestingCompany=${IS_TEST_COMPANY}&fatherCompanyId=${idFather}`;
          getCompaniesApi(token, filters).then((response) => {
            if (response === undefined) {
              const msg = messageError();

              notification["error"]({
                message: msg,
              });
              return;
            }
            if (response?.statusCode === 401) {
              notification["error"]({
                message: "Usuario no autorizado.",
              });
              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1000);
              return;
            } else {
              const filtradas = response.filter(
                (sucursales) => sucursales.companyId !== destinationId
              );
              setSucursalSelect(filtradas);
              setLoadingForm(false);
            }
          });
        }
      }
    });
  };
  function handleChangeRest(value) {
    setSenderId(value);
  }
  const importMenuFunction = async () => {
    setLoadingForm(true);
    if (senderId === undefined) {
      notification["error"]({
        message: "Debes seleccionar una sucursal.",
      });
    } else {
      let data = {
        DestinationCompanyId: destinationId,
        SenderCompanyId: senderId,
      };
      const result = await importMenuApi(token, data);
      if (result.statusCode === 400) {
        notification["error"]({
          message: result.description,
        });
      }
      if (result.statusCode === 200) {
        notification["success"]({
          message: result.description,
        });
        setIsVisible(false);
      }
    }
    setLoadingForm(false);
  };
  return (
    <Spin tip="Guardando..." spinning={loadingForm} size="large">
      <Form
        {...layout}
        className="groupName"
        form={form}
        onFinish={importMenuFunction}
      >
        <Col>
          <p>Selecciona una sucursal para importar su menú:</p>
        </Col>
        <Col md={10} xs={24}>
          {sucursalSelect && (
            <Select
              style={{ width: 280 }}
              placeholder="Sucursales"
              defaultValue={null}
              onChange={handleChangeRest}
            >
              {sucursalSelect.map((suc) => {
                return (
                  <Option key={suc.companyId} value={suc.companyId}>
                    {suc.name}
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>
        <Row style={{ marginTop: 20 }}>
          <div>
            <p className="alerta-text">
              <span className="nota-text">NOTA: </span>*Después de que hayas
              hecho clic en "Importar menú", te notificaremos cuando se termine
              de importar la carga del menú en el sistema*
            </p>
          </div>
        </Row>
        <Divider></Divider>

        <Row>
          <Col span={12}>
            <Button type="ghost" onClick={Cancel}>
              Cancelar
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              icon={<VerticalAlignBottomOutlined />}
              onClick={importMenuFunction}
            >
              Importar menú
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default ImportMenu;
