import React, { useState, useEffect } from 'react';
import { Divider, Layout, notification, Skeleton } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import { getAccessTokenApi } from '../../../api/auth';
import { getAllUserFiltersApi } from '../../../api/user';
import {
    messageError,
    messageErrorGeneral,
    userLogOut,
} from '../../../utils/general';
import ListUsers from '../../../components/ManagerSucursal/ListUsers';

import './Users.scss';
import { getFatherCompanyIdApi } from '../../../api/managers';

const Users = () => {
    const { Content } = Layout;
    const [users, setUsers] = useState([]);
    const [reloadUsers, setreloadUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadUsers]);

    const getUsers = async () => {
        const idFather = getFatherCompanyIdApi();
        console.log(idFather);
        if(idFather === 'undefined' || idFather === undefined ){
            setLoadingUsers(false)
            return notification["error"]({
                message: "Ocurrio un error, intentelo mas tarde."
            })
        }
        const filters = `?UserType=${1}&fatherCompanyId=${idFather}`;
        const result = await getAllUserFiltersApi(token, filters);

        if (result) {
            if (result.statusCode === 200) {
                const arrayRest = [
                    'DemoCompany',
                    'DemoEnvago',
                    'DemoRest',
                    'DemoTienda',
                    'DemoTienda1',
                ];
                let users = result.result.filter(
                    (ele) => arrayRest.includes(ele.companyId)
                    // (ele) => !arrayRest.includes(ele.companyId)
                );
                users.forEach((element, index) => {
                    element['key'] = index;
                });
                setUsers(users);
            } else {
                notification['error']({
                    message: messageErrorGeneral(),
                });
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }
        setLoadingUsers(false);
    };

    return (
        <Layout className="users">
            <HeaderTop
                title={'Usuarios'}
                // backIcon={true}
                backUrl="/manager"
            />

            <Content className="users__content">
                {!loadingUsers && (
                    <ListUsers
                        data={users}
                        loading={loadingUsers}
                        setreloadUsers={setreloadUsers}
                    />
                )}
                {loadingUsers && (
                    <div>
                        <div style={{ textAlign: 'right' }}>
                            <Skeleton.Button active size="large" />
                        </div>
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default Users;
