export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const COMPANY_ID = 'companyId';
export const COMPANY_IMAGE = 'companyImage';
export const COMPANY_NAME = 'companyName';
export const COMPANY_DISTANCE_MAX = 'companyDistanceMax';
export const USER_PATH = 'userPath';
export const FATHER_COMPANY_ID = 'FatherCompanyId';

export const IS_TEST_COMPANY = true;
export const IS_SHOW_IN_POOL = true;
export const IS_ACTIVE_COMPANY = true;
export const NAME_FATHER_COMPANY = 'DemoMatriz';

