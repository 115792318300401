import { basePath } from "./config";
import { FATHER_COMPANY_ID } from "../utils/constants";

export function getManagersApi(token) {
  let url = `${basePath}/father-companies`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function createManagerAdminApi(token, data) {
  const url = `${basePath}/Administration/CreateUser`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function createManagerCompanyAdminApi(token, data) {
  const url = `${basePath}/father-companies`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function linkToFatherCompanyApi(token, idCompany, data) {
  const url = `${basePath}/Companies/${idCompany}/link-to-father`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getFatherCompanyIdApi() {
  const fatherCompanyId = localStorage.getItem(FATHER_COMPANY_ID);

  if (
    !fatherCompanyId ||
    fatherCompanyId === "null" ||
    fatherCompanyId === null
  ) {
    return null;
  }

  return parseInt(fatherCompanyId);
}

export function getManagersFiltersApi(token, filters) {
  const url = `${basePath}/father-companies?${filters}`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getByIdentifierApi(token, identifier) {
  const url = `${basePath}/father-companies/get-by-identifier/${identifier}`;
  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function createImageManagerCompanyAdminApi(token, idComany, data) {
  const url = `${basePath}/father-companies/${idComany}/image`;

  const formData = new FormData();

  if (data.Image) {
    if (data.Image.length > 0) {
      console.log(data["Image"][0].originFileObj);
      formData.append("Image", data["Image"][0].originFileObj);
    }
  }

  const params = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getImageMatrix(token, id) {
  let url = `${basePath}/father-companies/${id}/image`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}


