import React, { useEffect, useState } from 'react';
import {
    Tabs,
    Table,
    Statistic,
    Typography,
    Row,
    Col,
    Tag,
    DatePicker,
    Button,
    Select,
    Spin,
    notification,
} from 'antd';
import {
    FilePdfOutlined,
    TableOutlined,
    LineChartOutlined,
    EyeOutlined,
    DollarOutlined,
    CreditCardOutlined,
    EyeInvisibleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi, getCompaniesApi } from '../../../api/company';
import { getOrderReportApi, editStatusComment } from '../../../api/order';
import { formatMoney, userLogOut, messageError } from '../../../utils/general';
import { isMobile } from '../../../utils/general';
import SellChart from '../../../components/Admin/SellChart';
import Modal from '../../General/Modal';
import ClientInfo from '../ClientInfo';

import './SellTable.scss';
import { getFatherCompanyIdApi } from '../../../api/managers';
import { IS_TEST_COMPANY } from '../../../utils/constants';

const SellTable = ({ allRest, manager }) => {
    const [sortedInfo, setSortedInfo] = useState({});
    const [filteredInfo, setFilteredInfo] = useState({});
    const [orders, setOrders] = useState([]);
    const [ordersPdf, setOrdersPdf] = useState([]);
    const [staticData, setStaticData] = useState({
        Profits: 0,
        TotalSells: 0,
    });
    const [dateSearch, setDateSearch] = useState({
        endDate: moment().format('LL'),
        startDate: moment().subtract(7, 'days').format('LL'),
    });
    const [loading, setLoading] = useState(true);
    const [disabledButton, setDisabledButton] = useState(true);
    const token = getAccessTokenApi();
    // const companyId = getCompanyIdApi();
    const [restaurants, setRestaurants] = useState([]);
    const [companyId, setCompanyId] = useState(getCompanyIdApi());
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [titleModal, setTitleModal] = useState('Información');

    const { TabPane } = Tabs;

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        const nowFormat = moment().format('YYYY-MM-DD');
        const dayStartFormat = moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD');

        let data = {
            CompanyId: companyId,
            Archived: true,
            StatusList: [6, 7],
            StartDate: dayStartFormat,
            EndDate: nowFormat,
            // ClientId: clientId
        };
        getOrderReportApi(token, data).then((res) => {
            if (res?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1000);
                return;
            } else {
                if (res) {
                    createValueTable(res);
                }
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    useEffect(() => {
        if (allRest) {
            const filters = `isTestingCompany=${IS_TEST_COMPANY}&active=${true}`;
            getCompaniesApi(token, filters)
                .then((response) => {
                    setRestaurants(response);
                })
                .catch((err) => {});
        }
        if(manager){
            const idFather = getFatherCompanyIdApi();
            const filters = `isTestingCompany=${IS_TEST_COMPANY}&active=${true}&fatherCompanyId=${idFather}`;
            getCompaniesApi(token, filters)
                .then((response) => {
                    setRestaurants(response);
                })
                .catch((err) => {});
        }
    }, [token, allRest, manager]);

    const createValueTable = (res) => {
        if (res !== undefined) {
            setFilteredInfo({});
            setSortedInfo({});
            let arrayOrder = [];
            let profitApi = 0;
            let totalSell = 0;
            res.forEach((element) => {
                element['key'] = element.orderId;
                element['canceledBy'] =
                    element.canceledBy == null ? '' : element.canceledBy;
                element['date'] = moment
                    .unix(element.orderDateTimeUnix)
                    .format('DD/MM/YYYY, h:mm a');
                element['totalPrice'] = `$ ${formatMoney(element.total)}`;

                arrayOrder.push(element);

                if (element.status === 6) {
                    totalSell = totalSell + 1;
                    profitApi = profitApi + element.total;
                }
            });

            if (filteredInfo.statusText) {
                if (filteredInfo.statusText[0] === 'Cancelada') {
                    profitApi = 0;
                    totalSell = 0;
                }
            }
            setOrders(arrayOrder);
            setOrdersPdf(arrayOrder);
            setStaticData({
                Profits: profitApi,
                TotalSells: totalSell,
            });
            if (res.length > 0) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        }
        setLoading(false);
    };

    function handleChangeRest(value) {
        setCompanyId(value);
    }

    const handleChange = (pagination, filters, sorter, extra) => {
        setSortedInfo(sorter);
        setFilteredInfo(filters);
        setOrdersPdf(extra.currentDataSource);
        handleUpdateStaticData(extra.currentDataSource);
    };

    const handleUpdateStaticData = (orders) => {
        let totalProfits = 0;
        let totalSell = 0;
        orders.forEach((order) => {
            if (order.status === 6) {
                totalSell++;
                totalProfits = totalProfits + order.total;
            }
        });

        setStaticData({
            Profits: totalProfits,
            TotalSells: totalSell,
        });
    };

    const getOrderType = (type) => {
        let orderType = '';
        switch (type) {
            case 'ADomicilio':
                orderType = 'A domicilio';
                break;
            case 'Recoger':
                orderType = 'Recoger';
                break;
            case 'ALaMesa':
                orderType = 'A la mesa';
                break;

            default:
                break;
        }

        return orderType;
    };

    const columns = [
        {
            title: '# Orden',
            dataIndex: 'orderNum',
            key: 'orderNum',
            sorter: (a, b) => a.orderNum - b.orderNum,
            sortOrder: sortedInfo.columnKey === 'orderNum' && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: 'Tipo',
            dataIndex: 'orderTypeText',
            key: 'orderTypeText',
            filters: [
                { text: 'Recoger', value: 'Recoger' },
                { text: 'A Domicilio', value: 'ADomicilio' },
                // { text: 'A la Mesa', value: 'ALaMesa' },
            ],
            filteredValue: filteredInfo.orderTypeText || null,
            onFilter: (value, record) => record.orderTypeText.includes(value),
            sorter: (a, b) => a.orderTypeText.length - b.orderTypeText.length,
            sortOrder:
                sortedInfo.columnKey === 'orderTypeText' && sortedInfo.order,
            ellipsis: true,
            render: (orderTypeText) => <>{getOrderType(orderTypeText)}</>,
        },
        {
            title: 'Fecha',
            dataIndex: 'orderDateTimeUnix',
            key: 'orderDateTimeUnix',
            sorter: (a, b) => a.orderDateTimeUnix - b.orderDateTimeUnix,
            sortOrder:
                sortedInfo.columnKey === 'orderDateTimeUnix' &&
                sortedInfo.order,
            ellipsis: true,
            render: (orderDateTimeUnix) => (
                <>
                    {moment
                        .unix(orderDateTimeUnix)
                        .format('DD/MM/YYYY, h:mm a')}
                </>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            sorter: (a, b) => a.total - b.total,
            sortOrder:
                sortedInfo.columnKey === 'totalPrice' && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: 'Tipo de Pago',
            dataIndex: 'payType',
            key: 'payType',
            ellipsis: true,
            align: 'center',
            filters: [
                { text: 'Efectivo', value: 1 },
                { text: 'Tarjeta', value: 2 },
            ],
            filteredValue: filteredInfo.payType || null,
            onFilter: (value, record) => record.payType === value,
            sorter: (a, b) => a.payType.length - b.payType.length,
            sortOrder: sortedInfo.columnKey === 'payType' && sortedInfo.order,
            render: (payType) => (
                <p>
                    {payType === 1 ? (
                        <DollarOutlined style={{ marginRight: 6 }} />
                    ) : (
                        <CreditCardOutlined style={{ marginRight: 6 }} />
                    )}
                    {payType === 1 ? 'Efectivo' : 'Tarjeta'}
                </p>
            ),
        },
        {
            title: 'Estatus',
            dataIndex: 'statusText',
            key: 'statusText',
            filters: [
                { text: 'Canceladas', value: 'Cancelada' },
                { text: 'Pagadas', value: 'Pagada' },
            ],
            filteredValue: filteredInfo.statusText || null,
            onFilter: (value, record) => record.statusText.includes(value),
            sorter: (a, b) => a.statusText.length - b.statusText.length,
            sortOrder:
                sortedInfo.columnKey === 'statusText' && sortedInfo.order,
            ellipsis: true,
            render: (statusText) => (
                <Tag color={statusText === 'Cancelada' ? 'red' : 'green'}>
                    {statusText.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Motivo',
            dataIndex: 'cancelationReason',
            key: 'cancelationReason',
            render: (cancelationReason) => (
                <>
                    {cancelationReason === null
                        ? 'No Aplica'
                        : cancelationReason}
                </>
            ),
            // filters: [
            // { text: 'Canceladas', value: 'Cancelada' },
            // { text: 'Pagadas', value: 'Pagada' },
            // ],
            // filteredValue: filteredInfo.statusText || null,
            // onFilter: (value, record) => record.cancelationReason.includes(value),
            // sorter: (a, b) => a.cancelationReason.length - b.cancelationReason.length,
            // sortOrder: sortedInfo.columnKey === 'cancelationReason' && sortedInfo.order,
            // ellipsis: true,
        },
        {
            title: 'Cancelado por',
            dataIndex: 'canceledBy',
            key: 'canceledBy',
            filters: [
                { text: 'Cliente', value: 'Client' },
                { text: 'Administrador', value: 'Admin' },
                { text: 'Colaborador', value: 'Operator' },
                { text: 'Sistema', value: 'Sistema' },
            ],
            filteredValue: filteredInfo.canceledBy || null,
            onFilter: (value, record) => record.canceledBy.includes(value),
            sorter: (a, b) => a.canceledBy.length - b.canceledBy.length,
            sortOrder:
                sortedInfo.columnKey === 'canceledBy' && sortedInfo.order,
            ellipsis: true,
            render: (canceledBy) => (
                <>
                    {canceledBy === 'Client'
                        ? 'Cliente'
                        : canceledBy === 'Admin'
                        ? 'Administrador'
                        : canceledBy === 'Operator'
                        ? 'Colaborador'
                        :  canceledBy === 'Sistema'
                        ? 'Sistema'
                        : '-'}
                </>
            ),
        },
        {
            title: 'Comentario',
            dataIndex: 'orderComment',
            key: 'orderComment',
            // render: (orderComment) => (
            //     <Button
            //         icon={<EyeOutlined />}
            //         onClick={() => showModalComment(orderComment)}
            //         type="primary"
            //     >
            //         Ver
            //     </Button>
            // ),
            render: (orderComment, order) => (
                <>
                    {orderComment === null ? (
                        'No hay comentario'
                    ) : (
                        <Button
                            className={
                                orderComment.isSeen === true
                                    ? 'comment-seen '
                                    : 'comment-notseen'
                            }
                            icon={
                                orderComment.isSeen === true ? (
                                    <EyeOutlined />
                                ) : (
                                    <EyeInvisibleOutlined />
                                )
                            }
                            onClick={() =>
                                showModalComment(orderComment, order)
                            }
                            type="primary"
                        >
                            {' '}
                            {orderComment.isSeen === true ? 'Visto' : 'Ver'}
                        </Button>
                    )}
                </>
            ),
        },
        {
            title: 'Detalle Cliente',
            key: 'orderId',
            fixed: 'right',
            width: 100,
            render: (order) => (
                <Button
                    icon={<EyeOutlined />}
                    onClick={() => showModalClient(order)}
                    type="primary"
                >
                    Ver
                </Button>
            ),
        },
    ];

    const columnsMobile = [
        {
            title: '# Orden',
            dataIndex: 'orderNum',
            key: 'orderNum',
            sorter: (a, b) => a.orderNum - b.orderNum,
            sortOrder: sortedInfo.columnKey === 'orderNum' && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: 'Total',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            sorter: (a, b) => a.total - b.total,
            sortOrder:
                sortedInfo.columnKey === 'totalPrice' && sortedInfo.order,
            ellipsis: true,
        },
        {
            title: 'Pago',
            dataIndex: 'payType',
            key: 'payType',
            ellipsis: true,
            align: 'center',
            filters: [
                { text: 'Efectivo', value: 1 },
                { text: 'Tarjeta', value: 2 },
            ],
            filteredValue: filteredInfo.payType || null,
            onFilter: (value, record) => record.payType === value,
            sorter: (a, b) => a.payType.length - b.payType.length,
            sortOrder: sortedInfo.columnKey === 'payType' && sortedInfo.order,
            render: (payType) => (
                <p>{payType === 1 ? 'Efectivo' : 'Tarjeta'}</p>
            ),
        },
        {
            title: 'Estatus',
            dataIndex: 'statusText',
            key: 'statusText',
            filters: [
                { text: 'Canceladas', value: 'Cancelada' },
                { text: 'Pagadas', value: 'Pagada' },
            ],
            filteredValue: filteredInfo.statusText || null,
            onFilter: (value, record) => record.statusText.includes(value),
            sorter: (a, b) => a.statusText.length - b.statusText.length,
            sortOrder:
                sortedInfo.columnKey === 'statusText' && sortedInfo.order,
            ellipsis: true,
            render: (statusText) => (
                <Tag color={statusText === 'Cancelada' ? 'red' : 'green'}>
                    {statusText}
                </Tag>
            ),
        },
        {
            title: 'Detalle Cliente',
            key: 'orderId',
            // dataIndex: 'id',
            fixed: 'right',
            width: 100,
            render: (order) => (
                <Button
                    icon={<EyeOutlined />}
                    onClick={() => showModalClient(order)}
                    type="primary"
                    size="small"
                >
                    Ver
                </Button>
            ),
        },
    ];

    const showModalClient = (order) => {
        setTitleModal('Información del Cliente');
        setIsVisibleModal(true);
        const { clientName, clientPhoneNumber, clientAddress, orderNum } =
            order;
        setModalContent(
            <ClientInfo
                clientName={clientName}
                clientPhoneNumber={clientPhoneNumber}
                clientAddress={clientAddress}
                orderNum={orderNum}
            />
        );
    };
    const showModalComment = (comment, order) => {
        setTitleModal('Comentario del Cliente');
        const data = {
            IsSeen: true,
            orderId: order.orderId,
            commentId: comment.id,
        };
        editStatusComment(token, data).then((response) => {
            if (response === undefined) {
                const msg = messageError();
                notification['error']({
                    message: msg,
                });
                setOrders((ords) => {
                    let copiaOrdenes = [...ords];
                    let encontrado = copiaOrdenes.findIndex(
                        (ordTmp) => ordTmp.orderId === order.orderId
                    );
                    if (encontrado >= 0) copiaOrdenes[encontrado].isSeen = true;
                    return copiaOrdenes;
                });
                return;
            }
        });
        setIsVisibleModal(true);

        setModalContent(<p>{comment.comment}</p>);
    };

    const searchDates = (val) => {
        if (val !== null) {
            if (val[0] !== null && val[1] !== null) {
                setLoading(true);
                let data = {
                    CompanyId: companyId,
                    Archived: true,
                    StatusList: [6, 7],
                    StartDate: val[0].format('YYYY-MM-DD'),
                    EndDate: val[1].format('YYYY-MM-DD'),
                    // ClientId: clientId
                };
                getOrderReportApi(token, data).then((res) => {
                    createValueTable(res);
                    setDateSearch({
                        startDate: val[0].format('LL'),
                        endDate: val[1].format('LL'),
                    });
                });
            }
        }
    };

    const exportPdf = () => {
        var mstrTitulo = `Reporte de Ventas del ${dateSearch.startDate} al ${dateSearch.endDate}`;
        var mstrPdf = 'reporte_ventas.pdf';

        var registros = [];
        let total_Sell = 0;
        let total_Profits = 0;

        let orders = ordersPdf;

        // let orderFilter = ordersPdf;

        // if (isMobile() === true) {
        //     orderFilter = handleFilterOrdersMovil(filteredInfo);
        // } else {
        //     orderFilter = handleFilterOrders(filteredInfo);
        // }

        // let orders = handleSorter(sortedInfo, orderFilter);

        // if (orders === undefined) {
        //     orders = orderFilter;
        // }

        orders.forEach((element) => {
            if (element.statusText === 'Pagada') {
                total_Sell++;
                total_Profits = total_Profits + element.total;
            }

            let orderType = '';
            switch (element.orderTypeText) {
                case 'ADomicilio':
                    orderType = 'A domicilio';
                    break;
                case 'Recoger':
                    orderType = 'Recoger';
                    break;
                case 'ALaMesa':
                    orderType = 'A la mesa';
                    break;

                default:
                    break;
            }

            let paymentType = '';
            if (element.payType === 1) {
                paymentType = 'Efectivo';
            } else {
                paymentType = 'Tarjeta';
            }

            let cancelBy = '';
            switch (element.canceledBy) {
                case 'Client':
                    cancelBy = 'Cliente';
                    break;
                case 'Admin':
                    cancelBy = 'Administrador';
                    break;
                case 'Operator':
                    cancelBy = 'Colaborador';
                    break;

                default:
                    break;
            }

            registros.push([
                element.orderNum,
                orderType,
                moment
                    .unix(element.orderDateTimeUnix)
                    .format('DD-MM-YYYY, h:mm a'),
                '$ ' + formatMoney(element.total),
                paymentType,
                element.statusText,
                element.cancelationReason === null
                    ? 'No aplica'
                    : element.cancelationReason,
                cancelBy,
            ]);
        });

        registros.push([
            'Ventas Totales',
            total_Sell,
            'Ganancia Totales',
            '$ ' + formatMoney(total_Profits),
            '',
            '',
        ]);

        let fecha = moment().format('YYYY-MM-DD');

        let doc = new jsPDF('landscape');
        doc.setFontSize(12);

        let xFecha = 245;
        let yFecha = 10;

        let totalPagesExp = '{total_pages_count_string}';

        let pageContent = function (data) {
            //header
            doc.text(15, 10, mstrTitulo);
            doc.text(fecha, xFecha, yFecha);
            // FOOTER
            let str = 'Página ' + data.pageCount;
            //Total page number plugin only available in jspdf v1.0+
            if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
            }
            doc.setFontSize(10);
            doc.text(
                str,
                data.settings.margin.left,
                doc.internal.pageSize.height - 10
            );
        };
        doc.autoTable({
            head: [
                [
                    '# Orden',
                    'Tipo',
                    'Fecha',
                    'Total',
                    'Tipo de Pago',
                    'Estatus',
                    'Motivo',
                    'Cancelado por',
                ],
            ],
            didDrawPage: pageContent,
            theme: 'grid',
            headStyles: {
                valign: 'middle',
                halign: 'center',
                fillColor: [49, 101, 120],
                textColor: [255, 255, 255],
            },
            body: registros,
            columnStyles: {
                text: {
                    cellWidth: 'wrap',
                },
            },
        });

        // call footer() after each doc.addPage()

        // and before doc.save() do not forget put
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        //spinner.style.display = 'none';
        doc.save(mstrPdf);
    };

    return (
        <>
            <Tabs defaultActiveKey="chart" centered>
                <TabPane
                    tab={
                        <span>
                            <LineChartOutlined />
                            Gráfica
                        </span>
                    }
                    key="chart"
                    // disabled
                >
                    <SellChart
                        loading={loading}
                        orders={orders}
                        allRest={allRest}
                        manager={manager}
                    />
                </TabPane>

                <TabPane
                    tab={
                        <span>
                            <TableOutlined />
                            Tabla
                        </span>
                    }
                    key="table"
                >
                    <TableSellMode
                        loading={loading}
                        columns={columns}
                        columnsMobile={columnsMobile}
                        orders={orders}
                        handleChange={handleChange}
                        dateSearch={dateSearch}
                        searchDates={searchDates}
                        staticData={staticData}
                        exportPdf={exportPdf}
                        disabledButton={disabledButton}
                        allRest={allRest}
                        manager={manager}
                        handleChangeRest={handleChangeRest}
                        restaurants={restaurants}
                        companyId={companyId}
                    />
                </TabPane>

                <Modal
                    title={titleModal}
                    isVisible={isVisibleModal}
                    setIsVisible={setIsVisibleModal}
                    footer={false}
                    destroyOnClose
                >
                    {modalContent}
                </Modal>
            </Tabs>
        </>
    );
};

function TableSellMode(props) {
    const {
        loading,
        columns,
        columnsMobile,
        orders,
        handleChange,
        dateSearch,
        searchDates,
        staticData,
        exportPdf,
        disabledButton,
        allRest,
        manager,
        handleChangeRest,
        restaurants,
        companyId,
    } = props;
    const { Title, Text } = Typography;
    const { Option } = Select;

    return (
        <>
            <Row gutter={16} className="first-section-report">
                <Col
                    span={16}
                    xs={24}
                    lg={16}
                    className="first-section-report__left"
                >
                    <Title level={3}>Búsqueda</Title>
                    <Text className="text-dates">
                        Fecha de búsqueda: {dateSearch.startDate} al{' '}
                        {dateSearch.endDate}
                    </Text>
                </Col>
                <Col
                    span={8}
                    xs={24}
                    lg={8}
                    className="first-section-report__right"
                >
                    <DatePicker.RangePicker
                        onCalendarChange={(val) => searchDates(val)}
                        size="large"
                    />
                </Col>

                {(allRest || manager) && (
                    <Col
                        span={24}
                        className="first-section-report__right"
                        style={{ marginTop: 15 }}
                    >
                        <Select
                            defaultValue={companyId}
                            style={{ width: 200, marginLeft: 20 }}
                            onChange={handleChangeRest}
                            size="large"
                            placeholder="Selec. Restaurante"
                        >
                            {restaurants.map((rest) => {
                                return (
                                    <Option
                                        key={rest.companyId}
                                        value={rest.companyId}
                                    >
                                        {rest.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Col>
                )}
            </Row>

            <Spin tip="Cargando..." spinning={loading}>
                {isMobile() === true ? (
                    <Table
                        columns={columnsMobile}
                        dataSource={orders}
                        onChange={handleChange}
                        size="small"
                        scroll={{ x: 500 }}
                    />
                ) : (
                    <Table
                        columns={columns}
                        dataSource={orders}
                        onChange={handleChange}
                        size="large"
                        scroll={{ x: 1000 }}
                    />
                )}
            </Spin>

            <Row gutter={16} className={'footer-section-report'}>
                <Col md={12} xs={24}>
                    <Row>
                        <Col md={12} xs={24}>
                            <Statistic
                                title="Total de Ventas"
                                value={staticData.TotalSells}
                            />
                        </Col>
                        <Col md={12} xs={24}>
                            <Statistic
                                title="Ganancias totales"
                                value={`$ ${formatMoney(staticData.Profits)}`}
                                precision={2}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col md={12} xs={24} className="text-right">
                    <Button
                        type="primary"
                        size="large"
                        icon={<FilePdfOutlined />}
                        onClick={exportPdf}
                        disabled={disabledButton}
                    >
                        Exportar Pdf
                    </Button>
                </Col>
            </Row>
        </>
    );
}

export default SellTable;
