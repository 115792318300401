// export const basePath = 'https://planbportales.com/controlbwebapi/api';
// export const apiKeyStripePublic =
//     'pk_live_51IBjbxDhgJ6XCIJmBeHrEjnrj2QSQLj5wuqiMsV2MOZEpLT3UlR0kZG7KWdGkeSDmUKFlFQ0gE0eQ1sU9LKZSYIC00DKWeEGTc';
export const bingKey =
    'AvMMCjv1m2WG02X0xGvdFAG0YUpIkf-t_f2WAU0X_WMDUm-ZrV9ZoQPxgM5FvFx0';
export const tokenSepoMexApi = '117ee279-5af9-4223-97f6-eb96a0e6b479';

//PRUEBA
export const apiKeyStripePublic =
    'pk_test_51IBjbxDhgJ6XCIJmC6gliH1ulYb3osBmQCm262jLGhusqzx4TxbZlyhZCdznK9yn3mYstKigRnzOm7fNzpLWNBGV00MznWZnZR';
// export const basePath = 'https://portales.planbsys.com/controlbwebapi/api';
// export const basePath = 'https://controlbtestapi.azurewebsites.net/api';
export const basePath = 'https://portales.planbsys.com/controlbwebapiQA/api';
