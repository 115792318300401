import React, { useState, useEffect } from 'react';
import {
    Button,
    List,
    Switch,
    Typography,
    Modal as ModalAntd,
    Tooltip,
    Tabs,
    notification,
    Row,
    Col,
} from 'antd';
import {
    StopOutlined,
    CheckOutlined,
    UsergroupAddOutlined,
    UsergroupDeleteOutlined,
    InfoCircleFilled,
    FileSearchOutlined,
    ShopOutlined,
    CreditCardFilled,
    UserAddOutlined,
    ApartmentOutlined
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../api/auth';
import {
    getCompaniesApi,
    changeActiveStatusApi,
    updateCompanyApi,
} from '../../../../api/company';
import Modal from '../../../General/Modal';
import SubscriptionRest from '../SubscriptionRest/SubscriptionRest';
import AddEditCompany from '../AddEditCompany/AddEditCompany';
import PaymentRest from '../PaymentRest/PaymentRest';
import LinkSucursal from '../LinkSucursal/LinkSucursal';
import AddUserForm from '../../../SuperAdmin/ListUsers/AddUserForm/AddUserForm';
import { getAllUserFiltersApi } from '../../../../api/user';
import { messageError, messageErrorGeneral } from '../../../../utils/general';
import { IS_TEST_COMPANY } from '../../../../utils/constants';

const { confirm } = ModalAntd;

export default function ListRestaurants() {
    const [loading, setLoading] = useState(true);
    const [reloadRestaurants, setReloadRestaurants] = useState(false);
    const [viewUsersActive, setViewUsersActive] = useState(true);
    const [restaurantsActive, setRestaurantsActive] = useState([]);
    const [restaurantsInactive, setRestaurantsInactive] = useState([]);
    const [restaurantsTest, setRestaurantsTest] = useState([]);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);

    const { TabPane } = Tabs;
    const { Text } = Typography;

    useEffect(() => {
        const token = getAccessTokenApi();
        const filter = `isTestingCompany=${IS_TEST_COMPANY}`;

        getCompaniesApi(token, filter).then((response) => {
            const activeRest = response.filter((ele) => ele.active === true);

            const inactiveRest = response.filter((ele) => ele.active === false);

            setRestaurantsActive(activeRest);
            setRestaurantsInactive(inactiveRest);

            setReloadRestaurants(false);
            setLoading(false);
        });

        const filterTest = `isTestingCompany=${true}`;
        getCompaniesApi(token, filterTest).then((response) => {
            //filter Test Company
            const arrayRest = [
                'DemoCompany',
                'DemoEnvago',
                'DemoRest',
                'DemoTienda',
                'DemoTienda1',
            ];
            const poolRests = response.filter(
                (ele) => !arrayRest.includes(ele.companyId)
            );

            setRestaurantsTest(poolRests);

            setReloadRestaurants(false);
            setLoading(false);
        });

        return () => {};
    }, [reloadRestaurants]);

    const addRestaurantModal = () => {
        setIsVisibleAddModal(true);
        setModalTitle(`Agregar establecimiento`);
        setModalContent(
            <AddEditCompany
                setIsVisibleAddModal={setIsVisibleAddModal}
                setReloadRestaurants={setReloadRestaurants}
                setModalContent={setModalContent}
            />
        );
    };

    return (
        <div className='list-users'>
            <div className='list-users__header'>
                <div className='list-users__header-switch'>
                    <Switch
                        defaultChecked
                        onChange={() => setViewUsersActive(!viewUsersActive)}
                    />
                    <Text>
                        {viewUsersActive
                            ? 'Restaurantes Activos'
                            : 'Restaurantes Inactivos'}
                    </Text>
                </div>

                <Button
                    type='primary'
                    onClick={addRestaurantModal}
                    icon={<ShopOutlined />}
                >
                    Nuevo Establecimiento
                </Button>
            </div>

            {/* <Row>
                <Col span={24}>
                    <Search
                        placeholder='Buscar Restaurantes Activos'
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Col>
            </Row> */}

            {viewUsersActive ? (
                <Tabs defaultActiveKey='1' centered>
                    <TabPane tab='Restaurantes Activos' key='1'>
                        <RestaurantsActive
                            restaurantsActive={restaurantsActive}
                            setReloadRestaurants={setReloadRestaurants}
                            loading={loading}
                        />
                    </TabPane>
                    <TabPane tab='Restaurantes en Pruebas' key='2'>
                        <RestaurantsTest
                            restaurantsTest={restaurantsTest}
                            setReloadRestaurants={setReloadRestaurants}
                            loading={loading}
                        />
                    </TabPane>
                </Tabs>
            ) : (
                <RestaurantsInactive
                    restaurantsInactive={restaurantsInactive}
                    setReloadRestaurants={setReloadRestaurants}
                    loading={loading}
                />
            )}
            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isVisibleAddModal}
                setIsVisible={setIsVisibleAddModal}
                footer={false}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function RestaurantsActive(props) {
    const { Title } = Typography;
    const { restaurantsActive, setReloadRestaurants, loading } = props;

    return (
        <>
            <Title level={3}>Lista de Restaurantes Activos</Title>
            <List
                className='users-active'
                itemLayout='horizontal'
                loading={loading}
                dataSource={restaurantsActive}
                pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                }}
                renderItem={(restaurant) => (
                    <RestaurantActive
                        restaurant={restaurant}
                        setReloadRestaurants={setReloadRestaurants}
                    />
                )}
            />
        </>
    );
}

function RestaurantActive(props) {
    const { restaurant, setReloadRestaurants } = props;

    const confirmDesactiveRestaurant = () => {
        confirm({
            title: 'Desactivar compañia',
            content: `¿Estás seguro que quieres desactivar la compañia ${restaurant.name}?`,
            okText: 'Si, Desactivar',
            okType: 'danger',
            cancelText: 'No, Cancelar',
            onOk() {
                desactivateRestaurant();
            },
        });
    };

    const desactivateRestaurant = async () => {
        const token = getAccessTokenApi();
        const companyId = restaurant.companyId;

        const result = await changeActiveStatusApi(token, companyId, false);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setReloadRestaurants(true);
                notification['success']({
                    message: 'Restaurante desactivado exitosamente',
                });
            }
        }
    };

    const confirmPoolRestaurant = () => {
        confirm({
            title: 'Mostar usuario',
            content: `¿Estás seguro que quieres ${
                restaurant.isShownInPool ? 'quitar del ' : 'añadir al '
            } pool a ${restaurant.name}?`,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No, Cancelar',
            onOk() {
                shownInPoolRestaurant();
            },
        });
    };

    const shownInPoolRestaurant = async () => {
        const token = getAccessTokenApi();
        restaurant['isShownInPool'] = !restaurant.isShownInPool;
        const result = await updateCompanyApi(token, restaurant);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setReloadRestaurants(true);
                notification['success']({
                    message: 'Operación realizada  exitosamente',
                });
            }
        }
    };

    const confirmTestRestaurant = () => {
        confirm({
            title: 'Mostar usuario',
            content: `¿Estás seguro que quieres pasar a modo de prueba el establecimiento ${restaurant.name}?`,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No, Cancelar',
            onOk() {
                fisnishModeTestlRestaurant();
            },
        });
    };

    const fisnishModeTestlRestaurant = async () => {
        const token = getAccessTokenApi();
        restaurant['isTestingCompany'] = !restaurant.isTestingCompany;

        const result = await updateCompanyApi(token, restaurant);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setReloadRestaurants(true);
                notification['success']({
                    message: 'Operación realizada  exitosamente',
                });
            }
        }
    };

    return (
        <List.Item
            actions={
                restaurant.isShownInPool
                    ? [
                          <Tooltip placement='top' title='Desactivar Compañia'>
                              <Button
                                  danger
                                  type='primary'
                                  onClick={confirmDesactiveRestaurant}
                                  icon={<StopOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip
                              placement='top'
                              title='Quitar del Pool la Compañia'
                          >
                              <Button
                                  danger
                                  type='ghost'
                                  onClick={confirmPoolRestaurant}
                                  icon={<UsergroupDeleteOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip placement='top' title='Pasar a modo Prueba'>
                              <Button
                                  //   danger
                                  ghost
                                  type='primary'
                                  onClick={confirmTestRestaurant}
                                  icon={<FileSearchOutlined />}
                              />
                          </Tooltip>,
                      ]
                    : [
                          <Tooltip placement='top' title='Desactivar Compañia'>
                              <Button
                                  danger
                                  type='primary'
                                  onClick={confirmDesactiveRestaurant}
                                  icon={<StopOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip
                              placement='top'
                              title='Pasar al Pool la  Compañia'
                          >
                              <Button
                                  type='primary'
                                  onClick={confirmPoolRestaurant}
                                  icon={<UsergroupAddOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip placement='top' title='Pasar a modo Prueba'>
                              <Button
                                  //   danger
                                  ghost
                                  type='primary'
                                  onClick={confirmTestRestaurant}
                                  icon={<FileSearchOutlined />}
                              />
                          </Tooltip>,
                      ]
            }
        >
            <List.Item.Meta
                // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
                title={`
                    ${restaurant.name ? restaurant.name : '...'}
                `}
                description={
                    <InfoRestaurant
                        restaurant={restaurant}
                        setReloadRestaurants={setReloadRestaurants}
                    />
                }
            />
        </List.Item>
    );
}

function InfoRestaurant(props) {
    const { restaurant, setReloadRestaurants } = props;
    const { Text } = Typography;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [titleModal, setTitleModal] = useState('');
    const showModalInfo = () => {
        setIsVisibleModal(true);
        setTitleModal(`Información de: ${restaurant.name}`);
        setModalContent(
            <SubscriptionRest
                companyId={restaurant.companyId}
                setIsVisibleModal={setIsVisibleModal}
            />
        );
    };
    const showModalPayment = () => {
        setIsVisibleModal(true);
        setTitleModal(`Modificar método de pago de: ${restaurant.name}`);
        setModalContent(
            <PaymentRest
                companyId={restaurant.companyId}
                setIsVisibleModal={setIsVisibleModal}
                useConekta={restaurant.useConekta}
                useStripe={restaurant.useStripe}
                setReloadRestaurants={setReloadRestaurants}
                restaurant={restaurant}
            />
        );
    };

    const showModalLinkSucursal = () => {
        setIsVisibleModal(true);
        setTitleModal(`Convertir en sucusal a: ${restaurant.name}`);
        setModalContent(
            <LinkSucursal
                companyId={restaurant.companyId}
                setIsVisibleModal={setIsVisibleModal}
                setReloadRestaurants={setReloadRestaurants}
                restaurant={restaurant}
            />
        );
    };

    const getUsers = async (company) => {
        const filters = `?UserType=${1}&CompanyId=${company}`;
        const token = getAccessTokenApi();
        const result = await getAllUserFiltersApi(token, filters);
        return result;
    };

    const showModalCreateUser = async (companyId) => {
        const usersAdmin = await getUsers(companyId);
        if (usersAdmin) {
            if (usersAdmin.statusCode === 200) {
                if (usersAdmin.result.length > 0) {
                    notification['warning']({
                        message:
                            'El establecimiento ya cuenta con un usuario administrador creado',
                        description:
                            'Solo se podrá dar de alta cuando no se tenga usuarios administradores el establecimiento',
                    });
                } else {
                    setIsVisibleModal(true);
                    setTitleModal(
                        `Creación de usuario para: ${restaurant.name}`
                    );
                    setModalContent(
                        <AddUserForm
                            companyId={restaurant.companyId}
                            setIsVisibleModal={setIsVisibleModal}
                            setreloadUsers={setReloadRestaurants}
                        />
                    );
                }
            } else {
                notification['error']({
                    message: messageErrorGeneral(),
                });
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }
    };

    return (
        <Row gutter={[10, 10]}>
            <Col spam={24}>
                <Text type='secondary'>{restaurant.fiscalName}</Text>
                <Text type='secondary'>{restaurant.fatherCompanyId ? ' (Sucursal)' : ''}</Text>
            </Col>
            <Col span={24}>
                <Button
                    icon={<UserAddOutlined />}
                    type='primary'
                    ghost
                    onClick={() => showModalCreateUser(restaurant.companyId)}
                >
                    Crear usuario
                </Button>
            </Col>
            <Col span={24}>
                <Button
                    icon={<InfoCircleFilled />}
                    type='primary'
                    ghost
                    onClick={() => showModalInfo()}
                >
                    Suscripción
                </Button>
            </Col>
            <Col span={24}>
                <Button
                    icon={<CreditCardFilled />}
                    type='primary'
                    ghost
                    onClick={() => showModalPayment()}
                >
                    Método de Pago
                </Button>
            </Col>
            <Col span={24}>
                <Button
                    icon={<ApartmentOutlined />}
                    type='primary'
                    ghost
                    onClick={() => showModalLinkSucursal()}
                >
                    Convertirlo en Sucursal
                </Button>
            </Col>

            <Modal
                destroyOnClose
                title={titleModal}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                footer={false}
            >
                {modalContent}
            </Modal>
        </Row>
    );
}

function RestaurantsInactive({
    restaurantsInactive,
    setReloadRestaurants,
    loading,
}) {
    const { Title } = Typography;

    return (
        <>
            <Title level={3}>Lista de Usuarios Inactivos</Title>
            <List
                className='users-active'
                itemLayout='horizontal'
                loading={loading}
                dataSource={restaurantsInactive}
                pagination={{
                    onChange: (page) => {
                        console.log(page);
                    },
                    pageSize: 6,
                }}
                renderItem={(restaurant) => (
                    <RestaurantInactive
                        restaurant={restaurant}
                        setReloadRestaurants={setReloadRestaurants}
                    />
                )}
            />
        </>
    );
}

function RestaurantInactive(props) {
    const { restaurant, setReloadRestaurants } = props;

    const confirmActiveRestaurant = () => {
        confirm({
            title: 'Activar usuario',
            content: `¿Estás seguro que quieres activar a ${restaurant.name}?`,
            okText: 'Si, Activar',
            okType: 'primary',
            cancelText: 'No, Cancelar',
            onOk() {
                activateRestaurant();
            },
        });
    };

    const activateRestaurant = async () => {
        const token = getAccessTokenApi();

        const companyId = restaurant.companyId;

        const result = await changeActiveStatusApi(token, companyId, true);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setReloadRestaurants(true);
                notification['success']({
                    message: 'Restaurante activado exitosamente',
                });
            }
        }
    };

    return (
        <List.Item
            actions={[
                <Tooltip placement='top' title='Activar Usuario'>
                    <Button
                        type='primary'
                        onClick={confirmActiveRestaurant}
                        icon={<CheckOutlined />}
                    />
                </Tooltip>,
            ]}
        >
            <List.Item.Meta
                // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
                title={`
                    ${restaurant.name ? restaurant.name : '...'}
                `}
                description={restaurant.fiscalName}
            />
        </List.Item>
    );
}

function RestaurantsTest(props) {
    const { Title } = Typography;
    const { restaurantsTest, setReloadRestaurants, loading } = props;

    return (
        <>
            <Title level={3}>Lista de Restaurantes en Pruebas</Title>
            <List
                className='users-active'
                itemLayout='horizontal'
                loading={loading}
                dataSource={restaurantsTest}
                pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                }}
                renderItem={(restaurant) => (
                    <RestaurantTest
                        restaurant={restaurant}
                        setReloadRestaurants={setReloadRestaurants}
                    />
                )}
            />
        </>
    );
}

function RestaurantTest(props) {
    const { restaurant, setReloadRestaurants } = props;

    const confirmDesactiveRestaurant = () => {
        confirm({
            title: 'Desactivar compañia',
            content: `¿Estás seguro que quieres desactivar la compañia ${restaurant.name}?`,
            okText: 'Si, Desactivar',
            okType: 'danger',
            cancelText: 'No, Cancelar',
            onOk() {
                desactivateRestaurant();
            },
        });
    };

    const desactivateRestaurant = async () => {
        const token = getAccessTokenApi();
        const companyId = restaurant.companyId;

        const result = await changeActiveStatusApi(token, companyId, false);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                restaurant['isTestingCompany'] = false;
                const result = await updateCompanyApi(token, restaurant);
                if (result === undefined) {
                    notification['error']({
                        message:
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                    });
                } else {
                    if (result.statusCode !== 200) {
                        let msg =
                            'Ocurrio un problema, Intente mas tarde por favor.';

                        if (result.statusCode === 409) {
                            msg = result.description;
                        }

                        notification['error']({
                            message: msg,
                        });
                    } else {
                        setReloadRestaurants(true);
                        notification['success']({
                            message: 'Restaurante desactivado exitosamente',
                        });
                    }
                }
                // setReloadRestaurants(true);
                // notification['success']({
                //     message: 'Restaurante desactivado exitosamente',
                // });
            }
        }
    };

    const confirmPoolRestaurant = () => {
        confirm({
            title: 'Mostar usuario',
            content: `¿Estás seguro que quieres ${
                restaurant.isShownInPool ? 'quitar del ' : 'añadir al '
            } pool a ${restaurant.name}?`,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No, Cancelar',
            onOk() {
                shownInPoolRestaurant();
            },
        });
    };

    const shownInPoolRestaurant = async () => {
        const token = getAccessTokenApi();
        restaurant['isShownInPool'] = !restaurant.isShownInPool;
        const result = await updateCompanyApi(token, restaurant);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setReloadRestaurants(true);
                notification['success']({
                    message: 'Operación realizada  exitosamente',
                });
            }
        }
    };

    const confirmTestRestaurant = () => {
        confirm({
            title: 'Mostar usuario',
            content: `¿Estás seguro que quieres terminar el modo de prueba de  ${restaurant.name}?`,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No, Cancelar',
            onOk() {
                fisnishModeTestlRestaurant();
            },
        });
    };

    const fisnishModeTestlRestaurant = async () => {
        const token = getAccessTokenApi();
        restaurant['isTestingCompany'] = !restaurant.isTestingCompany;

        const result = await updateCompanyApi(token, restaurant);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setReloadRestaurants(true);
                notification['success']({
                    message: 'Operación realizada  exitosamente',
                });
            }
        }
    };

    return (
        <List.Item
            actions={
                restaurant.isShownInPool
                    ? [
                          <Tooltip placement='top' title='Desactivar Compañia'>
                              <Button
                                  danger
                                  type='primary'
                                  onClick={confirmDesactiveRestaurant}
                                  icon={<StopOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip
                              placement='top'
                              title='Quitar del Pool la Compañia'
                          >
                              <Button
                                  danger
                                  type='ghost'
                                  onClick={confirmPoolRestaurant}
                                  icon={<UsergroupDeleteOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip
                              placement='top'
                              title='Quitar del modo Prueba'
                          >
                              <Button
                                  //   danger
                                  ghost
                                  type='primary'
                                  onClick={confirmTestRestaurant}
                                  icon={<FileSearchOutlined />}
                              />
                          </Tooltip>,
                      ]
                    : [
                          <Tooltip placement='top' title='Desactivar Compañia'>
                              <Button
                                  danger
                                  type='primary'
                                  onClick={confirmDesactiveRestaurant}
                                  icon={<StopOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip
                              placement='top'
                              title='Pasar al Pool la  Compañia'
                          >
                              <Button
                                  type='primary'
                                  onClick={confirmPoolRestaurant}
                                  icon={<UsergroupAddOutlined />}
                              />
                          </Tooltip>,
                          <Tooltip
                              placement='top'
                              title='Quitar del modo Prueba'
                          >
                              <Button
                                  //   danger
                                  ghost
                                  type='primary'
                                  onClick={confirmTestRestaurant}
                                  icon={<FileSearchOutlined />}
                              />
                          </Tooltip>,
                      ]
            }
        >
            <List.Item.Meta
                // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
                title={`
                    ${restaurant.name ? restaurant.name : '...'}
                `}
                description={
                    <InfoRestaurant
                        restaurant={restaurant}
                        setReloadRestaurants={setReloadRestaurants}
                    />
                }
            />
        </List.Item>
    );
}
