import { basePath } from "./config";

export function getCompleteMenu(token, idCompany) {
  const url = `${basePath}/Menu/GetCompleteMenu`;

  if (!idCompany) {
    let promResuelta = Promise.resolve({
      error: true,
    });
    return promResuelta;
  }

  const data = {
    CompanyId: idCompany,
    MenuClient: false,
  };

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getMenuGroupApi(token, data) {
  const url = `${basePath}/Menu/getMenuGroup`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getCompleteMenuClient(token, data) {
  const url = `${basePath}/Menu/GetCompleteMenu`;

  // if(!idCompany){

  //     let promResuelta = Promise.resolve({
  //         error: true
  //     });
  //     return promResuelta
  // }

  // const data = {
  //     CompanyId: idCompany
  // }

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getMenuStucture(token) {
  const url = `${basePath}/Menu/GetMenuStucture`;

  const params = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function addGroupMenu(token, data) {
  const url = `${basePath}/Menu/AddGroup`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function editGroupMenu(token, data) {
  const url = `${basePath}/Menu/EditGroup`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function addImageGroupMenu(token, data) {
  const url = `${basePath}/Menu/AddImageToMenuGroup`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function deleteGroupMenu(token, data) {
  const url = `${basePath}/Menu/DeleteGroup`;

  const params = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getGroupMenu(token, idCompany) {
  const url = `${basePath}/Menu/GetMenuGroups`;

  if (!idCompany) {
    let promResuelta = Promise.resolve({
      error: true,
    });
    return promResuelta;
  }

  const data = {
    CompanyId: idCompany,
  };

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//------ CATEGORY ------//

export function addCategoryMenu(token, data) {
  const url = `${basePath}/Menu/AddCategory`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function editCategoryMenu(token, data) {
  const url = `${basePath}/Menu/EditCategory`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function deleteCategoryMenu(token, data) {
  const url = `${basePath}/Menu/DeleteCategory`;

  const params = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

//------ ITEMS ------//

export function addDishMenu(token, data) {
  const url = `${basePath}/Menu/AddItem`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function addImageDishMenu(token, data) {
  const url = `${basePath}/Menu/AddImageToMenuItem`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function editDishMenu(token, data) {
  const url = `${basePath}/Menu/EditItem`;

  const params = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function deleteItemMenu(token, data) {
  const url = `${basePath}/Menu/DeleteItem`;

  const params = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function getMenuItemsApi(token, idCompany, searchWord, orderType) {
  const url = `${basePath}/Menu/GetMenuItems`;

  const data = {
    CompanyId: idCompany,
    SearchWord: searchWord,
    OrderType: orderType,
    MenuClient: true,
  };

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function updateTemplateMenu(token, data) {
  const url = `${basePath}/Menu/import-items-with-template`;
  const formData = new FormData();

  if (data.file) {
    if (data.file.length > 0) {
      formData.append("file", data["file"][0].originFileObj);
    }
  }
  const params = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function uploadExtraImagesApi(token, itemId, data) {
  const url = `${basePath}/item/${itemId}/images`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
export function getExtraImagesApi(token, itemId) {
  const url = `${basePath}/item/${itemId}/images`;

  const params = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}

export function deleteExtraImagesApi(token, itemId, idImage) {
  const url = `${basePath}/item/${itemId}/images/${idImage}`;

  const params = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
// IMPORTAR MENU SUCURSAL
export function importMenuApi(token, data) {
  const url = `${basePath}/Menu/Copy`;

  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      Accept: "*/*",
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {});
}
