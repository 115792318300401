import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  Typography,
  InputNumber,
  Popconfirm,
  Collapse,
  Skeleton,
  message,
  Select,
  Button,
  Radio,
  Image,
  Alert,
  Modal as ModalAntd,
  Empty,
  Spin,
  Row,
  Col,
  Divider,
  notification,
  Input,
} from "antd";
import moment from "moment";
import {
  DollarOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  TagFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { formatMoney, userLogOut } from "../../../utils/general";
import { getAccessTokenApi, logout } from "../../../api/auth";
import {
  getCompanyImageApi,
  getCompanyApi,
  getCompanyIdApi,
} from "../../../api/company";
import { createOrderApi } from "../../../api/order";
import { getCouponByCode } from "../../../api/promo";
import useAuth from "../../../hooks/useAuth";
import useOrder from "../../../hooks/useOrder";
import Modal from "../../../components/General/Modal";
import DeliveryCostForm from "../../../components/Client/DeliveryCostForm";
import { apiKeyStripePublic } from "../../../api/config";
import useCompany from "../../../hooks/useCompany";
import CheckoutForm from "../../../components/Client/CheckoutForm";
// import CheckoutMercadoPago from '../../../components/Client/CheckoutMercadoPago';
import CheckoutConekta from "../CheckoutConekta";
import {
  COMPANY_ID,
  COMPANY_IMAGE,
  COMPANY_NAME,
} from "../../../utils/constants";
import "./BasketForm.scss";

const promise = loadStripe(apiKeyStripePublic, {
  locale: "es-419",
});

const BasketForm = (props) => {
  const { setCounterItem, setIsModalVisible, infoCompany, suscriptionCompany } =
    props;

  const { orderGeneral, setOrderGeneral, reset } = useOrder();

  const { Title, Paragraph, Text } = Typography;
  const [paypalType, setPaypalType] = useState(
    orderGeneral.PayType === 0 ? 1 : orderGeneral.PayType
  );
  const [orderState, setOrderState] = useState(orderGeneral);
  const [reloadOrder, setReloadOrder] = useState(false);
  const [activeSpin, setActiveSpin] = useState(false);
  const [messageActiveSpin, setMessageActiveSpin] = useState("Cargando...");
  const [change, setChange] = useState(0);
  const { company, setCompany } = useCompany();
  const { user } = useAuth();
  const token = getAccessTokenApi();
  const companyImage = getCompanyImageApi();
  const [isModalVisibleCost, setIsModalVisibleCost] = useState(false);
  let history = useHistory();
  const [confirmOrder, setConfirmOrder] = useState(true);
  const [stripePaymentMethod, setStripePaymentMethod] = useState("");
  //new address
  const [isValidAddress, setisValidAddress] = useState(
    orderGeneral.OrderType !== 2
      ? true
      : orderGeneral.ClientAddress !== ""
      ? true
      : false
  );
  const [orderDelivery, setOrderDelivery] = useState(orderGeneral.delivery);
  const [orderDeliveryKM, setOrderDeliveryKM] = useState(orderGeneral.Distance);
  const [orderType, setOrderType] = useState(orderGeneral.OrderType);
  const [ignoredCostDelivery, setIgnoredCostDelivery] = useState(
    orderGeneral.IgnoreCostDelivery
  );
  const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
  const [acceptCardCredit, setAcceptCardCredit] = useState(true);
  const [couponCode, setCouponCode] = useState("");
  const [couponId, setCouponId] = useState(0);
  const [restoDescuento, setRestoDescuento] = useState(0);
  const companyId = getCompanyIdApi();

  const now = moment();

  // const [mercadoPagoPaymentId, setMercadoPagoPaymentId] = useState(0);
  // const [mercadoPagoPaymentMethod, setMercadoPagoPaymentMethod] =useState('');
  const [conektaPaymentMethod, setConektaPaymentMethod] = useState("");

  let typeOrder;
  let deliveyPlace = "";

  useEffect(() => {
    setOrderType(orderGeneral.OrderType);
    setisValidAddress(
      orderGeneral.OrderType !== 2
        ? true
        : orderGeneral.ClientAddress !== ""
        ? true
        : false
    );
    setOrderDelivery(orderGeneral.delivery);
    setOrderDeliveryKM(orderGeneral.Distance);
    setIgnoredCostDelivery(orderGeneral.IgnoreCostDelivery);
  }, [orderGeneral]);

  useEffect(() => {
    if (token == null) {
      userLogOut();
    }
  }, [token]);

  switch (orderGeneral.OrderType) {
    case 1:
      typeOrder = "Para Llevar";
      deliveyPlace = "Sucursal";
      break;

    case 2:
      typeOrder = "A Domicilio";
      deliveyPlace = orderGeneral.ClientAddress;
      break;

    case 3:
      typeOrder = "Para Comer Aqui";
      deliveyPlace = `Mesa: ${orderGeneral.TableNumber}`;

      break;

    default:
      break;
  }

  let [subtotal, setSubtotal] = useState(0);
  let [total, setTotal] = useState(0);
  // let [totalSinCupon, setTotalSinCupon] = useState(0);
  const [descuento, setDescuento] = useState(0);
  const [descuentoEnvio, setDescuentoEnvio] = useState(0);
  const [applyCoupon, setApplyCoupon] = useState("");
  const [couponApplied, setCouponApplied] = useState(false);

  const [tip, setTip] = useState(orderGeneral.TipPercent);
  const [typeOrderText, setTypeOrderText] = useState(typeOrder);

  const [disablePaymentCard, setDisablePaymentCard] = useState(false);

  useEffect(() => {
    let suma = 0;

    if (suscriptionCompany === "Basic") {
      orderState.orderLinesList.forEach((element) => {
        if (element.typeUoms !== undefined) {
          suma =
            suma +
            (element.extraPrice + parseFloat(element.priceUoms)) *
              element.quantity;
        } else {
          suma = suma + (element.extraPrice + element.price) * element.quantity;
        }
      });
    } else {
      let productosgratis = 0;
      let restarCupon = 0;
      // let quantitySinPromo = 0;
      orderState.orderLinesList.forEach((element, index) => {
        const elementosTemporales = orderState.orderLinesList.filter((e) => {
          let itemId = e.itemId;
          if (typeof itemId === "string") {
            itemId = e.itemId.split("/");
            itemId = parseInt(itemId[0]);
          }
          return itemId === element.itemId;
        });

        let quantityElements = 0;

        if (elementosTemporales.length > 0) {
          elementosTemporales.forEach((elemento) => {
            if (
              (elemento?.couponComplete?.type === 1 ||
                elemento?.couponComplete?.type === 2) &&
              elemento.typeUoms === undefined
            ) {
              quantityElements += elemento.quantity;
            }
          });

          if (quantityElements === 0) {
            quantityElements = element.quantity;
          }
        } else {
          if (element.couponComplete) {
            if (element.typeUoms === undefined) {
              quantityElements = 0;
            } else {
              quantityElements = element.quantity;
            }
          } else {
            quantityElements = element.quantity;
          }
          // quantityElements = 0; sin UOMS
        }

        // debugger;
        if (element.couponComplete !== null) {
          switch (element.couponComplete.type) {
            case 1:
              // if ((element.quantity / 2) <= element.couponComplete.maxPromos || element.couponComplete.maxPromos === 0) {

              productosgratis = Math.trunc(quantityElements / 2);
              if (element.typeUoms !== undefined) {
                restarCupon = productosgratis * element.priceUoms;
              } else {
                restarCupon = productosgratis * element.price;
              }

              // else {
              //     restarCupon = 0;
              //     quantitySinPromo = element.quantity - (element.couponComplete.maxPromos * 2);
              // }
              break;
            case 2:
              // if ((element.quantity / 3) <= element.couponComplete.maxPromos || element.couponComplete.maxPromos === 0) {

              productosgratis = Math.trunc(quantityElements / 3);
              if (element.typeUoms !== undefined) {
                restarCupon = productosgratis * element.priceUoms;
              } else {
                restarCupon = productosgratis * element.price;
              }

              // }
              // else {
              //     restarCupon = 0;
              //     quantitySinPromo = element.quantity - (element.couponComplete.maxPromos * 3);
              // }
              break;
            default:
              break;
          }
        }

        if (
          element.couponComplete !== null &&
          (element.couponComplete.type === 1 ||
            element.couponComplete.type === 2)
        ) {
          if (element.typeUoms !== undefined) {
            suma =
              suma +
              (element.extraPrice + parseFloat(element.priceUoms)) *
                element.quantity -
              restarCupon;

            // const resultado = suma + (element.extraPrice + parseFloat(element.priceUoms)) * element.quantity - restarCupon;

            // if (quantitySinPromo > 0) {
            //     const sumaSinCoupon = suma + (element.extraPrice + parseFloat(element.priceUoms)) * quantitySinPromo;
            //     suma = totalSinCupon + sumaSinCoupon;
            //     return;
            // }
            // else {
            //     console.log("entra aqui")
            //     setTotalSinCupon(resultado)
            // }
            // suma = resultado;
          } else {
            suma =
              suma +
              (element.extraPrice + element.price) * element.quantity -
              restarCupon;

            // const resta = ((element.quantity - quantitySinPromo) / 2) * element.price;
            // setTotalSinCupon(resta);

            // const resultado = suma + (element.extraPrice + element.price) * element.quantity - restarCupon;

            // if (quantitySinPromo > 0) {
            //     setTotalSinCupon(resta);

            //     const sumaSinCoupon = suma + (element.extraPrice + element.price) * quantitySinPromo;
            //     suma = totalSinCupon + sumaSinCoupon;

            //     return;
            // }
            // else {
            //     setTotalSinCupon(resultado)
            // }
            // suma = resultado;
          }
        } else if (
          element.couponComplete === null ||
          element.couponComplete.type !== 1 ||
          element.couponComplete.type !== 2
        ) {
          if (element.typeUoms !== undefined) {
            suma =
              suma +
              (element.extraPrice + parseFloat(element.priceUoms)) *
                quantityElements;
          } else {
            suma =
              suma + (element.extraPrice + element.price) * quantityElements;
          }
        }

        productosgratis = 0;
        restarCupon = 0;
      });
    }
    setSubtotal(suma);
    setTotal(suma);
    setReloadOrder(false);
    setCounterItem(orderGeneral.orderLinesList.length);

    setOrderGeneral({
      ...orderGeneral,
      Subtotal: suma,
      orderLinesList: orderState.orderLinesList,
    });

    return () => {
      setSubtotal(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadOrder]);

  useEffect(() => {
    if (company === null) {
      setConfirmOrder(false);
      const idComany = getCompanyIdApi();
      getCompanyApi(token, idComany)
        .then((response) => {
          if (response) {
            if (response?.statusCode === 401) {
              notification["error"]({
                message: "Usuario no autorizado",
              });

              setTimeout(() => {
                logout();
                window.location.reload();
              }, 1500);
              return;
            }
            if (response.error) {
              return false;
            }
            setCompany({
              ...company,
              Location: response.address,
              State: response.state,
              Neighborhood: response.neighborhood,
              ZipCode: response.zipCode,
              City: response.city,
              Telephone: response.phone,
              CompanyImage: response.companyImage,
              DeliveryCostKM: response.deliveryCostKM,
              DeliveryCost: response.deliveryCost,
              DeliveryExtraKmCost: response.deliveryExtraKmCost,
              DeliverryMaxDistance: response.deliverryMaxDistance,
              ServiceMaxDistance: response.serviceMaxDistance,
              AllowMultipleActiveOrders: response.allowMultipleActiveOrders,
              Name: response.name,
              PickUp: response.pickUp,
              ToTable: response.toTable,
              Delivery: response.delivery,
              Latitude: response.latitude,
              Longitude: response.longitude,
              CreditCard: response.creditCard,
              CashOnly: response.cashOnly,
              OrderMinimumPurchase: response.orderMinimumPurchase,
              UseStripe: response.useStripe,
              UseConekta: response.useConekta,
              ConektaPublicKey: response.conektaPublicKey,
            });
            setConfirmOrder(true);
          }
        })
        .catch((err) => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    isAvailablePaymentCard();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeOrderText, total, subtotal, tip, descuento, restoDescuento]);

  const [address, setAddress] = useState(deliveyPlace);
  const { Option } = Select;
  const { Panel } = Collapse;
  const { confirm } = ModalAntd;

  const onChangePayment = (e) => {
    setPaypalType(e.target.value);
    setOrderGeneral({ ...orderGeneral, PayType: e.target.value });
    if (e.target.value === 2) {
      setConfirmOrder(false);
      setMessageActiveSpin("Cargando...");
    } else {
      setConfirmOrder(true);
    }
  };

  function onChangePaypal(value) {
    setChange(value);
  }

  function confirmFunction(itemId) {
    const found = orderState.orderLinesList.findIndex(
      (item) => item.itemId === itemId
    );
    orderState.orderLinesList.splice(found, 1);
    setOrderState(orderState);
    setReloadOrder(true);
    setDescuento(0);
    message.success("Eliminado exitosamente");
  }

  function cancel(e) {}

  function incrementItem(dish) {
    // console.log(dish, 'item');
    // console.log(orderGeneral, 'orderGeneral');
    const found = orderState.orderLinesList.findIndex(
      (item) => item.itemId === dish.itemId
    );
    orderState.orderLinesList[found].quantity = dish.quantity + 1;
    setOrderState(orderState);
    setReloadOrder(true);
  }

  function decrementItem(dish) {
    const found = orderState.orderLinesList.findIndex(
      (item) => item.itemId === dish.itemId
    );
    orderState.orderLinesList[found].quantity = dish.quantity - 1;
    setOrderState(orderState);
    setReloadOrder(true);
  }

  function handleChangeTip(value) {
    setOrderGeneral({ ...orderGeneral, TipPercent: value });
    setTip(value);
  }

  function goHome() {
    localStorage.removeItem(COMPANY_ID);
    localStorage.removeItem(COMPANY_IMAGE);
    localStorage.removeItem(COMPANY_NAME);
    reset();
    history.push("/");
  }

  const confirmOrderFunction = () => {
    confirm({
      title: "Confirmar sucursal",
      icon: <ExclamationCircleOutlined />,
      content: `¿Estás seguro de crear la orden en la sucursal ${company.Name}? `,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        createOrder();
      },
      onCancel() {
        goHome();
      },
    });
  };

  const createOrder = async () => {
    if (company.OrderMinimumPurchase) {
      if (
        orderType !== 1 &&
        subtotal < company.OrderMinimumPurchase &&
        !couponApplied
      ) {
        notification["error"]({
          message: `Pedido mínimo de $ ${formatMoney(
            company.OrderMinimumPurchase
          )} `,
        });

        return;
      }

      //Tomar en cuenta el total de la orden si hay descuentos
      if (
        orderType === 2 &&
        couponApplied
        // (subtotal - descuento) + orderDelivery < company.OrderMinimumPurchase
      ) {
        // const totalFinal = ignoredCostDelivery
        // ? total +
        //   (subtotal * tip) / 100 -
        //   descuento +
        //   restoDescuento
        // : total +
        //   (subtotal * tip) / 100 +
        //   orderDelivery -
        //   descuento +
        //   restoDescuento;

        if (subtotal < company.OrderMinimumPurchase) {
          notification["error"]({
            message: `Pedido mínimo de $ ${formatMoney(
              company.OrderMinimumPurchase
            )}`,
          });

          return;
        }
      }
    }

    setMessageActiveSpin("Enviando la orden...");
    setActiveSpin(true);

    if (isValidAddress === false) {
      //mostar modal de direccion
      setIsModalVisibleAddress(true);
      setActiveSpin(false);
      return false;
    }

    orderState.ClientId =
      user[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];

    orderState.ChangeOf = change;
    orderState.PayType = paypalType;
    orderState.TipPercent = tip;
    orderState.ClientAddress = address;
    orderState.OrderType = orderType;
    orderState.delivery = orderDelivery;
    orderState.Distance = orderDeliveryKM;
    orderState.Coupon = couponId;

    if (orderType === 2) {
      orderState["IgnoreDeliveryCost"] = ignoredCostDelivery;
    } else {
      orderState["IgnoreDeliveryCost"] = false;
    }

    let totalOrder = 0;
    if (orderType === 2) {
      if (ignoredCostDelivery) {
        totalOrder = total + (subtotal * tip) / 100;
      } else {
        totalOrder = total + (subtotal * tip) / 100 + orderDelivery;
      }
    } else {
      totalOrder = total + (subtotal * tip) / 100;
    }

    if (orderGeneral.ChangeOf === null) {
      orderGeneral.ChangeOf = 0;
    }

    if (orderState.PayType === 1) {
      if (orderState.ChangeOf !== 0) {
        if (orderState.ChangeOf < totalOrder) {
          setActiveSpin(false);

          notification["error"]({
            message: "El cambio debe ser IGUAL o MAYOR que el total",
          });

          return false;
        }
      }
    }

    //payment intent id
    orderState.StripePaymentMethod = stripePaymentMethod;
    // orderState.MercadoPagoPaymentId = mercadoPagoPaymentId;
    orderState.ConektaPaymentMethod = conektaPaymentMethod;

    let orderSend = orderState;

    if (orderSend.orderLinesList && orderSend.orderLinesList.length > 0) {
      orderSend.orderLinesList.forEach((orderLine) => {
        orderLine.measurementUnits = parseFloat(orderLine.measurementUnits);
        orderLine.selectedOptionIds = [];
        if (orderLine.options) {
          orderLine.options.forEach((opt) => {
            orderLine.selectedOptionIds = [
              ...orderLine.selectedOptionIds,
              opt.sectionOptionId,
            ];
          });
        }
        orderLine.itemId = parseInt(orderLine.itemId);
      });
    }

    const result = await createOrderApi(token, orderSend);

    if (result !== undefined) {
      if (result.statusCode === 200) {
        notification["success"]({
          message: "Orden creada exitosamente",
        });
        setActiveSpin(false);
        reset();
        history.push(`/statusOrder/${result.result.orderId}`);
        // history.push({
        //     pathname: '/statusOrder',
        //     state: {
        //         orderId: result.result.orderId,
        //         // orderActive: result.result,
        //     },
        // });
      } else {
        let message = "Ocurio un error, intentelo de nuevo";

        if (result.statusCode === 404) {
          message = result.description;

          setActiveSpin(false);
          notification["error"]({
            message: message,
          });
        } else if (result.statusCode === 400) {
          setActiveSpin(false);
          notification["error"]({
            message: result.description,
          });
        } else if (result.statusCode === 405) {
          setActiveSpin(false);
          notification["error"]({
            message: result.description,
          });
          history.push("/disable/restaurant");
        } else if (result.statusCode === 401) {
          setActiveSpin(false);
          notification["error"]({
            message: result.description,
          });
          logout();
          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        } else {
          setActiveSpin(false);
          notification["error"]({
            message: message,
          });
        }
      }
    } else {
      let message = "Ocurio un error, intentelo de mas tarde";
      setActiveSpin(false);
      setIsModalVisible(false);
      notification["error"]({
        message: message,
      });
    }
  };

  const showModalDeliveryCost = () => {
    setIsModalVisibleCost(true);
  };
  const applyCode = async (couponCode) => {
    if (couponCode === "") {
      let message = "Código de descuento no puede estar vacío.";
      notification["warning"]({
        message: message,
      });
      return;
    }
    if (couponApplied === true) {
      let message = "No puedes aplicar más de un cupón en una orden";
      notification["error"]({
        message: message,
      });
    } else {
      const result = await getCouponByCode(token, companyId, couponCode);
      if (result.statusCode === 200) {
        if (couponApplied === false) {
          setApplyCoupon(couponCode);
          let finalTotalDiscount = 0;

          if (
            result.result.coupon.type === 1 ||
            result.result.coupon.type === 2
          ) {
            //*****-----APPLY DISCOUNT TYPE 1-2 -------****//
            const gruopById = [];
            let elementosTemporales;

            result.result.itemIds.forEach((id) => {
              elementosTemporales = orderState.orderLinesList.filter((e) => {
                let itemId = e.itemId;
                if (typeof itemId === "string") {
                  itemId = e.itemId.split("/");
                  itemId = parseInt(itemId[0]);
                }

                return itemId === id;
              });

              if (elementosTemporales.length > 0) {
                gruopById.push(elementosTemporales);
              }
            });

            gruopById.forEach((group) => {
              let totalquantity = 0;
              let pricediscount = 0;
              let productFree = 0;
              let priceItem = 0;
              let arrayUoms = [];
              group.forEach((prod) => {
                if (prod.typeUoms) {
                  const arregloFilter = group.filter(
                    (element) =>
                      prod.measurementUnits === element.measurementUnits
                  );
                  // si en algun grupo de uoms sucede que:
                  //  cada uno de los elements de sus sets
                  //  es igual a los elementos de arreglofilter
                  const estanTodos = arrayUoms.some((uoms) =>
                    uoms.every((u) => arregloFilter.includes(u))
                  );
                  if (!estanTodos) arrayUoms.push(arregloFilter);
                }
              });

              arrayUoms.forEach((group, idx) => {
                const len = arrayUoms[idx].length;
                const { priceUoms, quantity } = arrayUoms[idx][0];
                totalquantity = len * quantity;
                priceItem = priceUoms;
                pricediscount += Math.trunc(totalquantity / 2) * priceItem;
              });

              group.forEach((prod) => {
                if (!prod.typeUoms) {
                  totalquantity += prod.quantity;
                  priceItem = prod.price;
                }
              });

              if (pricediscount === 0)
                switch (result.result.coupon.type) {
                  case 1:
                    productFree = Math.trunc(totalquantity / 2);
                    pricediscount = productFree * priceItem;
                    break;

                  case 2:
                    productFree = Math.trunc(totalquantity / 3);
                    pricediscount = productFree * priceItem;
                    break;
                  default:
                    break;
                }
              finalTotalDiscount += pricediscount;
            });

            if (gruopById.length === 0) {
              let message = "No hay productos que coincidan con la promoción ";
              notification["error"]({
                message: message,
              });
            } else {
              if (finalTotalDiscount > 0) {
                setDescuento(finalTotalDiscount);
                setCouponId(result.result.coupon.cuponId);
                setCouponApplied(true);
              } else {
                let message =
                  "La cantidad de productos no aplica para la promoción.";
                notification["warning"]({
                  message: message,
                });
              }
            }
          } else {
            //*****-----APPLY DISCOUNT TYPE 5-6-7 -------****//
            if (
              subtotal >= result.result.coupon.minimum &&
              (result.result.coupon.type === 5 ||
                result.result.coupon.type === 6 ||
                result.result.coupon.type === 7)
            ) {
              if (
                result.result.coupon.type === 5 &&
                subtotal >= result.result.coupon.minimum
              ) {
                if (result.result.coupon.discountPrice >= total) {
                  let descuentoAplicado =
                    result.result.coupon.discountPrice - total;
                  setRestoDescuento(descuentoAplicado);
                }
                setDescuento(result.result.coupon.discountPrice);
                setCouponId(result.result.coupon.cuponId);
                setCouponApplied(true);
              }
              if (
                result.result.coupon.type === 6 &&
                subtotal >= result.result.coupon.minimum
              ) {
                let orderDiscount =
                  (total * result.result.coupon.discountPercent) / 100;
                setDescuento(orderDiscount);
                setCouponId(result.result.coupon.cuponId);
                setCouponApplied(true);
              }
              if (
                result.result.coupon.type === 7 &&
                subtotal >= result.result.coupon.minimum
              ) {
                if (orderDelivery > 0) {
                  setDescuentoEnvio(orderDelivery);
                  setOrderDelivery(0);
                  setCouponId(result.result.coupon.cuponId);
                  setCouponApplied(true);
                } else {
                  let message = "No hay costo de envío en la orden";
                  notification["error"]({
                    message: message,
                  });
                }
              }
            } else if (
              subtotal <= result.result.coupon.minimum &&
              (result.result.coupon.type === 5 ||
                result.result.coupon.type === 6 ||
                result.result.coupon.type === 7)
            ) {
              let message = `La compra minima para aplicar el cupón es de: $${formatMoney(
                result.result.coupon.minimum
              )}`;
              notification["error"]({
                message: message,
              });
            }
          }
        }
      } else if (result.statusCode === 400) {
        notification["error"]({
          message: result.description,
        });
      } else if (result.statusCode === 404) {
        notification["error"]({
          message: result.description,
        });
      } else {
        let message = "Cupón no valido";
        notification["error"]({
          message: message,
        });
      }
    }
  };

  const getNewPrice = (promo, originalPrice) => {
    let price = originalPrice;
    if (promo) {
      if (isValidPromo(promo)) {
        if (promo.type === 3) {
          const newPrice = price - promo.discountPrice;

          if (newPrice > 0) {
            price = newPrice;
          } else if (newPrice <= 0) {
            price = 0;
          }
        } else if (promo.type === 4) {
          const pricePorcent = (promo.discountPercent * originalPrice) / 100;
          const newPricePorcent = price - pricePorcent;

          price = newPricePorcent;
        }
      }
    }

    return price;
  };
  const showItemFreePromo = (promo) => {
    let showPromo = {
      show: false,
      text: "",
    };
    if (promo) {
      const { type } = promo;

      // console.log(promo);

      if (type === 1 || type === 2) {
        showPromo.show = isValidPromo(promo);

        if (type === 1) {
          showPromo.text = "2x1";
        } else if (type === 2) {
          showPromo.text = "3x2";
        }
      }
    }

    return showPromo;
  };

  const getCountSave = (promo, originalPrice) => {
    let price = originalPrice;
    let save = "";
    if (promo) {
      if (isValidPromo(promo)) {
        if (promo.type === 3) {
          const newPrice = price - promo.discountPrice;

          if (newPrice > 0) {
            price = newPrice;
            save = `Ahorra $ ${formatMoney(promo.discountPrice)}.`;
          } else if (newPrice <= 0) {
            save = `Ahorra $ ${formatMoney(price)}.`;
          }
        } else if (promo.type === 4) {
          // const pricePorcent =
          //     (promo.discountPercent * originalPrice) / 100;
          // const newPricePorcent = price - pricePorcent;

          // price = newPricePorcent;

          save = `Ahorras ${promo.discountPercent} %.`;
        }
      }
    }

    return save;
  };

  const isAvailablePaymentCard = () => {
    let disable = false;
    let totalOrder = 0;

    if (typeOrderText === "A Domicilio") {
      if (ignoredCostDelivery) {
        totalOrder =
          total + (subtotal * tip) / 100 - descuento + restoDescuento;
      } else {
        totalOrder =
          total +
          (subtotal * tip) / 100 +
          orderDelivery -
          descuento +
          restoDescuento;
      }
    } else {
      totalOrder = total + (subtotal * tip) / 100 - descuento + restoDescuento;
      // if(total +
      // (subtotal * tip) / 100 -
      // descuento +
      // restoDescuento)
    }

    if (totalOrder === 0) {
      disable = true;
    }
    setDisablePaymentCard(disable);
  };

  function isValidPromo(promo) {
    const {
      durationType,
      endDate,
      startDate,
      applicableDays,
      timeStart,
      timeEnd,
    } = promo;

    let isValid = false;

    if (durationType === "range-dates") {
      const startDatePromo = moment.unix(startDate);
      const endDatePromo = moment.unix(endDate);

      if (
        moment(now).isBefore(endDatePromo) &&
        moment(now).isSameOrAfter(startDatePromo)
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else {
      const daysPromoArray = applicableDays.split(",");
      let nowDay = moment().days();
      if (nowDay === 0) {
        //asign sunday value 7
        nowDay = 7;
      }
      //search rest of the days
      const found = daysPromoArray.find(
        (element) => parseInt(element) === nowDay
      );

      if (found) {
        // isValid = true;
        //CHECK TIME
        if (
          now.hour() >= moment.unix(timeStart, "h:mma").hour() &&
          now.hour() <= moment.unix(timeEnd, "h:mma").hour()
        ) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    }

    // return true;
    return isValid;
  }
  return (
    <div className="basket-form">
      <Spin spinning={activeSpin} tip={messageActiveSpin} size="large">
        <Row>
          <Col span={6} className="basket-form__container-image">
            {company == null && companyImage === null && (
              <Skeleton.Avatar active={true} size="large" shape="square" />
            )}
            <Image width={60} src={companyImage} />
          </Col>
          <Col span={16} className="basket-form__container-right">
            {company == null && infoCompany == null && (
              <Skeleton active paragraph={{ rows: 2 }} />
            )}

            <Title level={3}>{company !== null ? company.Name : ""}</Title>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Paragraph style={{ textAlign: "center" }}>
              {company !== null
                ? `${company.Location}, Col. ${company.Neighborhood} ${company.ZipCode}, ${company.City} ${company.State}.`
                : ""}
            </Paragraph>
          </Col>
        </Row>

        <Divider>Detalles</Divider>

        {/* <Row className="basket-form__container">
                <Col span={14} className="basket-form__container-left">
                    <Paragraph>Tiempo de entrega</Paragraph>
                </Col>
                <Col span={10} className="basket-form__container-right">
                    <Paragraph>15-25 min</Paragraph>
                </Col>
            </Row> */}

        <Row className="basket-form__container">
          <Col span={14} className="basket-form__container-left">
            <Paragraph>Tipo de entrega</Paragraph>
          </Col>
          <Col span={10} className="basket-form__container-right">
            <Paragraph>{typeOrderText}</Paragraph>
          </Col>
        </Row>

        {/* <Row className="basket-form__container">
                <Col span={14} className="basket-form__container-left">
                    <Paragraph>Sucursal</Paragraph>
                </Col>
                <Col span={10} className="basket-form__container-right">
                    <Paragraph>{company !== null ? company.address : infoCompany.Location}</Paragraph>
                </Col>
            </Row> */}

        <Row className="basket-form__container">
          <Col
            span={orderType === 2 ? 24 : 14}
            className="basket-form__container-left"
          >
            <Paragraph>Ubicación de Entrega</Paragraph>
          </Col>
          <Col
            span={orderType === 2 ? 24 : 10}
            className={[
              "basket-form__container-right",
              orderType === 2 && "text-center",
            ]}
          >
            {address !== "" && <Paragraph>{address}</Paragraph>}
            {address === "" && (
              <Alert message="¡Dirección de entrega PENDIENTE!" banner />
            )}
          </Col>
        </Row>

        {orderType === 2 && isValidAddress && (
          <>
            <Divider>Costo de Envío</Divider>

            <div className="basket-form__container">
              {/* <Row className="basket-form__container-cost-delivery">
                                <Col span={24}>
                                    <Title level={5}>Importante</Title>
                                    <Paragraph>Costo minimo de envío</Paragraph>
                                    <Paragraph>
                                        ${formatMoney(infoCompany.DeliveryCost)} (0-
                                        {infoCompany.DeliveryCostKM}km)
                                    </Paragraph>
                                    <Paragraph>
                                        Km extra ${formatMoney(infoCompany.DeliveryExtraKmCost)}
                                    </Paragraph>
                                </Col>
                            </Row> */}
              <Row>
                <Col span={12}>
                  <Button
                    type="primary"
                    onClick={() => showModalDeliveryCost()}
                  >
                    Detalle de envío
                  </Button>
                </Col>
                <Col span={12} className="text-left">
                  <Alert showIcon message="Aprobado" type="success" />
                </Col>
              </Row>
            </div>
          </>
        )}

        <Divider>Tu Orden</Divider>

        {orderState.orderLinesList.length > 0 && (
          <div className="basket-form__container-add">
            <Button
              size="small"
              type="primary"
              onClick={() => setIsModalVisible(false)}
            >
              Agregar Platillos
            </Button>
          </div>
        )}

        <div
          className={
            orderState.orderLinesList.length > 3
              ? "basket-form__container-groupsItem"
              : ""
          }
        >
          {orderState.orderLinesList.map((item) => {
            return (
              <Row
                className="basket-form__container-items"
                key={item.itemIdUnique}
                // key={item.itemId}
              >
                {suscriptionCompany !== "Basic" && (
                  <Col span={24}>
                    <Col span={15}>
                      {getNewPrice(item.couponComplete, item.originalPrice) !==
                        item.originalPrice && (
                        <p className="banner-discount">
                          <TagFilled />
                          {getCountSave(
                            item.couponComplete,
                            item.originalPrice
                          )}
                        </p>
                      )}
                      {showItemFreePromo(item.couponComplete).show && (
                        <p className="banner-discount item-free">
                          <TagFilled />
                          {showItemFreePromo(item.couponComplete).text}
                        </p>
                      )}
                    </Col>
                  </Col>
                )}
                <Col span={14}>
                  <Title level={4}>{item.name}</Title>
                  <Row>
                    <p className="gray">
                      $
                      {formatMoney(
                        item.priceUoms ? parseFloat(item.priceUoms) : item.price
                      )}
                    </p>
                    {item.measurementUnits > 0 && (
                      <p className="gray">
                        -(
                        {item.measurementUnits.toFixed(2)}){item.typeUoms}
                      </p>
                    )}
                  </Row>

                  {item.instructions !== "" && (
                    <Collapse ghost>
                      <Panel header="Instrucciones" key="1">
                        <p>{item.instructions}</p>
                      </Panel>
                    </Collapse>
                  )}
                  <div className="basket-form__container-items">
                    {item.options.map((option) => (
                      <Row key={option.sectionOptionId}>
                        <Col span={24}>
                          <Text className="section-items">
                            <Text strong>{`${option.nameSection}: `}</Text>
                            <Text>{`${option.name}`}</Text>

                            {option.price && (
                              <Text className="gray margin-left">
                                ${formatMoney(option.price)}
                              </Text>
                            )}
                          </Text>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </Col>
                <Col span={10}>
                  <Row className="counter">
                    <Col span={8}>
                      <Button
                        disabled={item.quantity === 1 || couponApplied}
                        onClick={() => decrementItem(item)}
                        danger
                      >
                        -
                      </Button>
                    </Col>
                    <Col span={8}>{item.quantity}</Col>
                    <Col span={8}>
                      <Button
                        disabled={couponApplied}
                        onClick={() => incrementItem(item)}
                        danger
                      >
                        +
                      </Button>
                    </Col>
                    <Col span={24} className="right">
                      <Popconfirm
                        title={`¿Estás seguro de eliminar el platillo: ${item.name}?`}
                        onConfirm={() => confirmFunction(item.itemId)}
                        onCancel={cancel}
                        okText="Si"
                        cancelText="No"
                        disabled={couponApplied}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          disabled={couponApplied}
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </div>

        {orderState.orderLinesList.length === 0 && (
          <Empty
            className="empty-basket"
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 60,
            }}
            description={<span>No hay ningun platillo</span>}
          >
            <Button
              size="small"
              type="primary"
              onClick={() => setIsModalVisible(false)}
            >
              Agregar
            </Button>
          </Empty>
        )}

        <Row className="basket-form__container">
          <Col span={14} className="basket-form__container-left">
            <Paragraph>Total de la orden</Paragraph>
          </Col>
          <Col span={10} className="basket-form__container-right">
            <Paragraph>${formatMoney(subtotal)}</Paragraph>
          </Col>
        </Row>
        <Row className="basket-form__container">
          <Col span={14} className="basket-form__container-left">
            <Paragraph>Envío</Paragraph>
          </Col>
          <Col span={10} className="basket-form__container-right">
            {typeOrderText === "A Domicilio" && orderDelivery === null && (
              <Skeleton
                className="cost-delivey"
                title
                active
                paragraph={false}
              ></Skeleton>
            )}
            {typeOrderText === "A Domicilio" &&
              orderDelivery !== null &&
              !ignoredCostDelivery && (
                <Paragraph>${formatMoney(orderDelivery)}</Paragraph>
              )}
            {typeOrderText === "A Domicilio" &&
              orderDelivery !== null &&
              ignoredCostDelivery && (
                <Paragraph strong mark>
                  Pendiente
                </Paragraph>
              )}
            {typeOrderText !== "A Domicilio" && (
              <Paragraph>${formatMoney(0)}</Paragraph>
            )}
          </Col>
        </Row>
        {suscriptionCompany !== "Basic" && couponApplied && (
          <Row className="basket-form__container">
            <Col span={18} className="basket-form__container-left">
              <Row>
                <Paragraph type="danger">Descuento aplicado</Paragraph>
                <Paragraph type="danger">
                  ({applyCoupon.toUpperCase()})
                </Paragraph>
              </Row>
            </Col>
            <Col span={6} className="basket-form__container-right">
              <Paragraph type="danger">
                - $
                {formatMoney(descuentoEnvio > 0 ? descuentoEnvio : descuento)}
              </Paragraph>
            </Col>
          </Row>
        )}

        <Row className="basket-form__container">
          <Col span={12} className="basket-form__container-left">
            <Paragraph>Propina</Paragraph>
          </Col>
          <Col span={12} className="basket-form__container-right">
            <Select
              value={tip}
              defaultValue={10}
              style={{ width: 140 }}
              onChange={handleChangeTip}
            >
              <Option value={0}>0% (${0.0})</Option>
              <Option value={5}>
                5% (${formatMoney((subtotal * 5) / 100)})
              </Option>
              <Option value={10}>
                10% (${formatMoney((subtotal * 10) / 100)})
              </Option>
              <Option value={15}>
                15% (${formatMoney((subtotal * 15) / 100)})
              </Option>
              <Option value={20}>
                20% (${formatMoney((subtotal * 20) / 100)})
              </Option>
              <Option value={25}>
                25% (${formatMoney((subtotal * 25) / 100)})
              </Option>
            </Select>
          </Col>
        </Row>
        {suscriptionCompany !== "Basic" && (
          <Row className="basket-form__container">
            <Col span={12} className="basket-form__container-left">
              <Paragraph style={{ marginTop: 15 }}>
                Código de descuento
              </Paragraph>
            </Col>
            <Col span={12} className="basket-form__container-right">
              <Input
                style={{
                  width: "100%",
                  marginTop: 10,
                }}
                placeholder="CODIGO01"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                disabled={couponApplied}
              ></Input>
              <Button
                className="margin-check"
                type="primary"
                onClick={() => applyCode(couponCode)}
                disabled={couponApplied}
              >
                Aplicar
              </Button>
            </Col>
          </Row>
        )}
        <Divider></Divider>
        <Row className="basket-form__container">
          <Col span={14} className="basket-form__container-left">
            <Title level={5}>Total</Title>
          </Col>
          <Col span={10} className="basket-form__container-right">
            <Title level={4}>
              $
              {/* {formatMoney(
                                typeOrderText === 'A Domicilio'
                                    ? total + (subtotal * tip) / 100 + orderDelivery
                                    : total + (subtotal * tip) / 100
                            )} */}
              {formatMoney(
                typeOrderText === "A Domicilio"
                  ? ignoredCostDelivery
                    ? total +
                      (subtotal * tip) / 100 -
                      descuento +
                      restoDescuento
                    : total +
                      (subtotal * tip) / 100 +
                      orderDelivery -
                      descuento +
                      restoDescuento
                  : total + (subtotal * tip) / 100 - descuento + restoDescuento
              )}
            </Title>
          </Col>
        </Row>

        <Divider>Método de Pago</Divider>

        <div className="basket-form__container-paypal">
          <Radio.Group
            defaultValue={orderGeneral.PayType}
            size="large"
            onChange={onChangePayment}
            value={paypalType}
          >
            <Radio.Button
              value={1}
              disabled={infoCompany.CashOnly ? false : true}
            >
              {" "}
              <DollarOutlined /> Efectivo
            </Radio.Button>
            <Radio.Button
              value={2}
              disabled={
                infoCompany.CreditCard && acceptCardCredit
                  ? disablePaymentCard
                  : true
              }
            >
              {" "}
              <CreditCardOutlined /> Tarjeta T/C
            </Radio.Button>
          </Radio.Group>

          {paypalType === 2 && infoCompany.CreditCard && (
            <Row className="change">
              {company !== null && company.UseStripe && (
                <Elements stripe={promise}>
                  <CheckoutForm
                    setConfirmOrder={setConfirmOrder}
                    setStripePaymentMethod={setStripePaymentMethod}
                    setActiveSpin={setActiveSpin}
                    setAcceptCardCredit={setAcceptCardCredit}
                  />
                </Elements>
              )}
              {company !== null && company.UseConekta && (
                <CheckoutConekta
                  setConfirmOrder={setConfirmOrder}
                  setConektaPaymentMethod={setConektaPaymentMethod}
                  setActiveSpin={setActiveSpin}
                  setAcceptCardCredit={setAcceptCardCredit}
                  conektaPublicKey={company.ConektaPublicKey}
                  // total={
                  //     typeOrderText === 'A Domicilio'
                  //         ? ignoredCostDelivery
                  //             ? total + (subtotal * tip) / 100
                  //             : total +
                  //               (subtotal * tip) / 100 +
                  //               orderDelivery
                  //         : total + (subtotal * tip) / 100
                  // }
                />
              )}
              {/* <Col span={24} className="change-left">
                            <Title level={5} type="danger">¡Aviso!</Title>
                            <Paragraph>El repartidor acudirá con la terminal para el pago.</Paragraph>
                        </Col> */}
            </Row>
          )}

          {paypalType === 1 && infoCompany.CashOnly && (
            <Row className="change">
              <Col span={6} className="change-left">
                <Paragraph>Cambio de</Paragraph>
              </Col>
              <Col span={18} className="change-right">
                <InputNumber
                  min={0}
                  value={change}
                  defaultValue={0}
                  formatter={(value) =>
                    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  // parser={(value) =>
                  //     value.replace(/\$\s?|(,*)/g, '')
                  // }
                  onChange={onChangePaypal}
                  style={{
                    width: "50%",
                  }}
                />
              </Col>
            </Row>
          )}
        </div>

        <Button
          disabled={
            orderState.orderLinesList.length === 0 || confirmOrder === false
          }
          type="primary"
          danger
          block
          onClick={confirmOrderFunction}
        >
          Confirmar Pedido
        </Button>

        {orderType === 2 && (
          <Modal
            isVisible={isModalVisibleCost}
            setIsVisible={setIsModalVisibleCost}
            title={"Costo de Envío"}
            footer={false}
          >
            <DeliveryCostForm
              setIsModalVisibleCost={setIsModalVisibleCost}
              address={address}
              infoCompany={infoCompany}
              history={null}
            />
          </Modal>
        )}

        <Modal
          isVisible={isModalVisibleAddress}
          setIsVisible={setIsModalVisibleAddress}
          title={"Direción"}
          footer={false}
        >
          <DeliveryCostForm
            setIsModalVisibleCost={setIsModalVisibleCost}
            address={""}
            infoCompany={infoCompany}
            history={"client-first"}
            action="add-first"
            setAddressClient={setAddress}
            setOrderDelivery={setOrderDelivery}
            setisValidAddress={setisValidAddress}
            setIsModalVisibleAddress={setIsModalVisibleAddress}
            setTypeOrderText={setTypeOrderText}
            setOrderType={setOrderType}
            setOrderDeliveryKM={setOrderDeliveryKM}
          />
        </Modal>
      </Spin>
    </div>
  );
};

export default BasketForm;
