import React, { useState, useEffect } from 'react';
import { Divider, Layout, notification, Skeleton } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import { getAccessTokenApi } from '../../../api/auth';
import { getManagersApi } from '../../../api/managers';
import {
    messageError,
    messageErrorGeneral,
    userLogOut,
} from '../../../utils/general';
import ListManagers from '../../../components/SuperAdmin/ListManagers/ListManagers';

import './Managers.scss';

const Managers = () => {
    const { Content } = Layout;
    const [managersCompany, setManagersCompany] = useState([]);
    const [reloadUsers, setreloadUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getManagers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadUsers]);

    const getManagers = async () => {
        const result = await getManagersApi(token);

        if (result) {
            if (result.statusCode === 200) {
                const arrayRest = [
                    'DemoCompany',
                    'DemoEnvago',
                    'DemoRest',
                    'DemoTienda',
                    'DemoTienda1',
                ];
                let managers = result.result.filter(
                    (ele) => !arrayRest.includes(ele.companyId)
                );
                managers.forEach((element, index) => {
                    element['key'] = index;
                });
                setManagersCompany(managers);
            } else {
                notification['error']({
                    message: messageErrorGeneral(),
                });
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }
        setLoadingUsers(false);
    };

    return (
        <Layout className="users">
            <HeaderTop
                title={'Managers (Sucursales)'}
                // backIcon={true}
                backUrl="/administrative"
            />

            <Content className="users__content">
                {!loadingUsers && (
                    <ListManagers
                        data={managersCompany}
                        loading={loadingUsers}
                        setreloadUsers={setreloadUsers}
                    />
                )}
                {loadingUsers && (
                    <div>
                        <div style={{ textAlign: 'right' }}>
                            <Skeleton.Button active size="large" />
                        </div>
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                        <Divider />
                        <Skeleton active paragraph={{ rows: 2 }} />
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default Managers;
