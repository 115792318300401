import React, { useEffect, useState } from 'react';
import { notification, Tabs } from 'antd';
import { DollarCircleOutlined, BarChartOutlined } from '@ant-design/icons';
import SellTable from '../../../components/Admin/SellTable';
import TopSelling from '../../../components/Admin/TopSelling';
import { getFatherCompanyIdApi } from '../../../api/managers';

export default function Reports() {
    const { TabPane } = Tabs;

    useEffect(() => {
        const idFather = getFatherCompanyIdApi();
        if(idFather === 'undefined' || idFather === undefined ){
            window.location.href = '/manager';
        }
       
    }, [])

    return (
        <div>
            <Tabs defaultActiveKey="1" centered className="report-tabs">
                <TabPane
                    tab={
                        <span className="report-tabs__title">
                            <DollarCircleOutlined />
                            Control de Ventas
                        </span>
                    }
                    key="1"
                >
                    <SellTable manager />
                </TabPane>
                <TabPane
                    tab={
                        <span className="report-tabs__title">
                            <BarChartOutlined />
                            Ranking
                        </span>
                    }
                    key="2"
                >
                    <TopSelling manager />
                </TabPane>
            </Tabs>
        </div>
    );
}
