import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Form, Typography, Input, Space, Button, notification , Upload, Divider, message, Image} from 'antd';
import { SearchOutlined, FilterOutlined, UserAddOutlined, UploadOutlined, PictureOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import moment from 'moment';
import Modal from '../../General/Modal';
import AddUserForm from './AddUserForm'
import { createImageManagerCompanyAdminApi, createManagerCompanyAdminApi, getImageMatrix } from '../../../api/managers';
import { getAccessTokenApi } from '../../../api/auth';

import './ListManagers.scss';

export default function ListManagers(props) {
    const { data, loading, setreloadUsers } = props;
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filteredInfo, setFilteredInfo] = useState({});
    // const [sortedInfo, setSortedInfo] = useState({});
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [contentModal, setContentModal] = useState();
    const { Title } = Typography;

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        // this.searchInput = node;
                    }}
                    placeholder={`Buscar`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type='primary'
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button
                        onClick={() => handleReset(clearFilters)}
                        size='small'
                        style={{ width: 90 }}
                    >
                        Limpiar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                : '',
        filteredValue: filteredInfo[dataIndex] || null,
        onFilterDropdownVisibleChange: (visible) => {},
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const clearAll = () => {
        setFilteredInfo({});
        // setSortedInfo({});
    };

    const handleOpenModalUser = (idCompanyFather) => {
        setTitleModal("Crear un usuario");
        setContentModal(<AddUserForm idCompanyFather={idCompanyFather} setIsVisibleModal={setIsVisibleModal} setreloadUsers={setreloadUsers}  />);
        setIsVisibleModal(true);
    }

    const handleOpenModalCompany = () => {
        setTitleModal("Crear una compañia");
        setContentModal(<AddSucursalCompanyForm setIsVisibleModal={setIsVisibleModal}  setreloadUsers={setreloadUsers} />);
        setIsVisibleModal(true);
    }

    const handleEditModalCompany = (idCompany) => {
        setTitleModal("Editar una compañia");
        setContentModal(<AddSucursalCompanyForm setIsVisibleModal={setIsVisibleModal}  setreloadUsers={setreloadUsers} idCompany={idCompany} />);
        setIsVisibleModal(true);
    }

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <h3>{text}</h3>,
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Fecha de creación',
            dataIndex: 'creationDate',
            key: 'creationDate',
            render: (creationDate) => <p>{moment.unix(creationDate).format('L')}</p>
        },
        // {
        //     title: 'Estatus',
        //     key: 'active',
        //     dataIndex: 'active',
        //     filters: [
        //         { text: 'Activo', value: true },
        //         { text: 'Inactivo', value: false },
        //     ],
        //     filteredValue: filteredInfo.active || null,
        //     onFilter: (value, record) => record.active === value,
        //     render: (active) => (
        //         <Tag color={active ? 'green' : 'red'}>
        //             {active ? 'Activo'.toUpperCase() : 'Incativo'.toUpperCase()}
        //         </Tag>
        //     ),
        // },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size='middle'>
                    <Button onClick={()=>handleOpenModalUser(record.id)} type="primary" icon={<UserAddOutlined />}>Crear Usuario</Button>
                    <Button onClick={()=>handleEditModalCompany(record.id)} type="primary" ghost icon={<PictureOutlined />}>Subir Imagen Compañia</Button>
                    {/* <a>Invite {record.id}</a> */}
                    {/* <a>Delete</a> */}
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Row gutter={[10, 20]}>
                <Col xs={24} md={16}>
                    <Title level={2}>Lista de compañias con sucursales</Title>
                </Col>
                <Col xs={24} md={8}>
                    <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button>
                    <Button onClick={handleOpenModalCompany} type='primary'>Crear una Matrix</Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={data}
                loading={loading}
                onChange={handleChange}
                scroll={{ x: 950 }}
            />
            <Modal
                destroyOnClose
                title={titleModal}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                footer={false}
            >
                {contentModal}
            </Modal>
        </div>
    );
}

const AddSucursalCompanyForm = ({setreloadUsers, setIsVisibleModal, idCompany}) => {
    const token = getAccessTokenApi();
    const [loadingForm, setLoadingForm] = useState(false);
    const [form] = Form.useForm();
    const [filesList, setFileList] = useState({});
    const [urlCompany, setUrlCompany] = useState('')

    useEffect(() => {
        if(idCompany){            
            getImageMatrix(token, idCompany)
                .then((image) => {
                  if (image?.statusCode === 200) setUrlCompany(image.result.path);
                })
                .catch((err) => console.log(err));
        }
    }, [idCompany, token])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const createCompanyIdhandle = (e) => {
        let text = e.target.value;
        const arrayText = text.split(' ');
        let companyId = '';
        arrayText.forEach((element) => {
            if (element !== '') {
                companyId = companyId + capitalizeFirstLetter(element);
            }
        });
        form.setFieldsValue({
            Identifier: companyId,
        });
    };

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('¡Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.warning('¡La imagen supera los 3MB recomendados!');
        }
        //   return isJpgOrPng && isLt2M;
        return false;
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    const onFinish = async (values) => {

        let result;
        if(idCompany){
            console.log(values);
            result = await createImageManagerCompanyAdminApi(token, idCompany, values)
        } else  {

            result = await createManagerCompanyAdminApi(token, values);
        }


        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
            setLoadingForm(false);
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
                setLoadingForm(false);
            } else {
                //recreo correctamenta
                form.resetFields();
                notification['success']({
                    message: 'Operación realizada exitosamente',
                });
                setLoadingForm(false);

                setIsVisibleModal(false);
                setreloadUsers(true);
            }
        }
    };


    return (    
    <Form
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      form={form}
    >
        {idCompany && <>
            <Divider>Logo Restaurante</Divider>
            <div className="container-image">
                <Image
                    width={200}
                    src={urlCompany !== '' ? urlCompany : 'https://coacademy-server-jc.com/uploads/courses/images/890.jpg'}
                />
            </div>

            <Form.Item
                name="Image"
                label="Logo de la empresa"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                    {
                        required: true,
                        message: 'Suba una imagen',
                    },
                ]}
            >
                <Upload
                    name="Image"
                    beforeUpload={beforeUpload}
                    listType="picture"
                    multiple={false}
                    onChange={handleChange}
                    fileList={filesList}
                    // disabled={completeFiles.circulationCardFrontId}
                >
                    <Button
                        icon={<UploadOutlined />}
                    >
                        Click para cargar la imagen
                    </Button>
                </Upload>
            </Form.Item>
        </>}
        {!idCompany && <>
            <Divider>Información de la compañia</Divider>
            <Form.Item
                label="Nombre de la compañia"
                name="name"
                rules={[{ required: true, message: 'Ingrese un nombre.' }]}
            >
                <Input placeholder='Demo Company' onChange={createCompanyIdhandle} />
            </Form.Item>

            <Form.Item
                name='Identifier'
                label='Id de la compañía'
                rules={[{ required: true }]}
                tooltip='No debe llevar espacios este campo.'
            >
                <Input placeholder='DemoCompany' disabled />
            </Form.Item>
            </>}


      <Form.Item wrapperCol={{ offset: 6, span: 12 }}>
        <Button type="primary" htmlType="submit" block loading={loadingForm}>
          Enviar
        </Button>
      </Form.Item>
    </Form>
    )
}
