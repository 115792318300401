import React from 'react';
import { Typography, Row, Col } from 'antd';
import moment from 'moment';
import ListRestaurants from '../../components/ManagerSucursal/Sucursales/ListRestaurants';

import './ManagerSucursal.scss';

export default function ManagerSucursal() {
    const { Title } = Typography;
    return (
        <div className="super-admin">
            <Row>
                <Col xs={24} md={20}>
                    <Title>Administrador de Sucursales</Title>
                </Col>
                <Col xs={24} md={4}>
                    Fecha: {moment().format('LL')}
                </Col>
            </Row>
            <ListRestaurants />
        </div>
    );
}
